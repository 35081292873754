@import 'variables.scss';

table {
    tbody {
        tr {
            td {
                &.keyword-list {
                    padding: 1px !important;
                    overflow: hidden;
                    button {
                        &.keyword-list-btn {
                            width: 100%;
                            text-align: left;
                            padding: 10px;
                            border: 1px solid transparent;
                            border-radius: 5px;
                            background-color: transparentize($default-border-color, 0.8);
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        
                            &:hover, &:focus {
                                border-color: transparentize($default-border-color, 0.5);
                                background-color: transparentize($default-border-color, 0.5);
                            }
                        
                            span {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                &.keyword {
                                    font-size: 13px;
                                    color: $default-text-color-dark;
                                    display: block;
                                }
                                &.label, &.description {
                                    font-size: 11px;
                                    color: $default-text-color-light;
                                    display: block;
                                    padding-top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}