@import "variables.scss";

.amenities-consumption-list-container {
    width: 100%;
    overflow: auto;
    max-height: 50vh;

    table {
        &.amenities-consumption-list-table {
            table-layout: fixed;
            border-collapse: collapse;
            border-spacing: 0 5px;

            thead, tbody {
                tr {
                    
                    &.header, &.body-row, &.header-secondary {
                        box-shadow: 0 2px 10px rgba(0,0,0,.06);
                        
                        td, th {
                            background-color: rgba(255, 255, 255, 0.9);
                            min-width: 150px;
                            text-align: left;
                            padding: 5px 10px;
                            box-sizing: border-box;
                            
                            &.fixed {
                                background-color: rgb(255, 255, 255);
                                position: sticky !important;
                                z-index: 1;

    
                                --_first-col-width: 150px;
    
                                &:nth-child(1) {
                                    min-width: var(--_first-col-width);
                                    max-width: var(--_first-col-width);
                                    left: 0;
                                    padding-right: 15px !important;
                                }
                            }
    
                            --_row-radius: 5px;
                            &:first-child {
                                border-top-left-radius: var(--_row-radius);
                                
                            }
                            &:last-child {
                                border-top-right-radius: var(--_row-radius);
                            }
    
                        }
                    }
                }
            }

            thead {
                height: 80px;
                border-spacing: 0 0px;
                tr {
                    gap: 0;
                    &.header {
                        th {
                            white-space: nowrap;
                            font-weight: bold;
                            color: $default-text-color-dark;
                            text-transform: uppercase;
                            font-size: 13px;
                            padding: 10px;
                            background-color: lighten($default-theme-color, 40%) !important;
                            position: sticky !important;
                            top: 0; // Stick on top
                            z-index: 1;
                            height: 35px;
                            margin-bottom: 0;
                            vertical-align: middle;

                            &.fixed {
                                z-index: 2;
                                border-bottom: 2px solid $default-theme-color;
                                vertical-align: bottom !important;
                            }
                        }
                    }
                    &.header-secondary {
                        th {
                            white-space: nowrap;
                            margin-top: 0;
                            font-weight: bold;
                            color: $default-text-color-dark;
                            text-transform: uppercase;
                            font-size: 13px;
                            padding: 10px;
                            border-bottom: 2px solid $default-theme-color;
                            background-color: lighten($default-theme-color, 40%) !important;
                            position: sticky !important;
                            height: 45px;
                            top: 35px; // Stick on top
                            z-index: 1;

                            &.fixed {
                                z-index: 2;
                            }
                        }
                    }

                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid transparentize($default-border-color, 0.5);
                    &.body-row {
                        td {
                            white-space: break-spaces;
                            color: $default-text-color;

                        }
                    }
                }
            }
        }
    }
}