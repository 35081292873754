@import "variables.scss";

.event-booking-list-page-filter-container {
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .filter-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        label {
            margin: 0 !important;
            white-space: nowrap;
        }

        .date-and-btn-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;

            button {
                box-shadow: unset;
                margin: 0;
                font-weight: bold;
                padding: 5px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: unset;
            }

            input {
                flex: 1;
            }
        }

        input {
            &.switch {
                margin: 0 !important;
                padding: 0 !important;
                width: 30px;
                height: 16px;
                border-radius: 15px;

                &::after {
                    width: 10px;
                    height: 10px;
                }
                &:checked {
                    --tranx: 15px;
                }
            }
        }
    }


    .filter-divider {
        height: 20px;
        width: 1px;
        background-color: transparentize($default-border-color, 0.5);
    }
}


.event-booking-list-container {
    width: 100%;
    overflow: auto;
    max-height: 75vh;

    table {
        &.event-booking-list-table {
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0 5px;

            thead, tbody {
                tr {
                    &.header, &.body-row {
                        box-shadow: 0 2px 10px rgba(0,0,0,.06);
                        
                        td, th {
                            background-color: rgba(255, 255, 255, 0.9);
                            min-width: 150px;
                            text-align: left;
                            padding: 5px 10px;
                            box-sizing: border-box;
                            
                            &.fixed {
                                background-color: rgb(255, 255, 255);
                                position: sticky !important;
                                z-index: 1;
    
                                --_first-col-width: 50px;
                                --_second-col-width: 200px;
                                --_third-col-width: 100px;
    
                                &:nth-child(1) {
                                    min-width: var(--_first-col-width);
                                    max-width: var(--_first-col-width);
                                    left: 0;
                                }
                                &:nth-child(2) {
                                    min-width: var(--_second-col-width);
                                    max-width: var(--_second-col-width);
                                    left: var(--_first-col-width);
                                }
                                &:nth-child(3) {
                                    min-width: var(--_third-col-width);
                                    max-width: var(--_third-col-width);
                                    left: calc(var(--_first-col-width) + var(--_second-col-width));
                                    box-shadow: 5px 0 10px -5px rgba(0,0,0,.06);
                                }
                            }
    
                            // First non fixed column
                            &.first-scrollable-child {
                                padding-left: 15px !important;
                            }
    
                            .option-btn-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
    
                                button {
                                    --_opt-btn-size: 30px;
                                    width: var(--_opt-btn-size);
                                    height: var(--_opt-btn-size);
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid transparent;
                                    border-radius: calc(var(--_opt-btn-size) * 2);
                                    background-color: transparentize($default-border-color, 0.65);
                                    color: $default-text-color-light;
                                }
                            }
    
                            --_row-radius: 5px;
                            &:first-child {
                                border-top-left-radius: var(--_row-radius);
                            }
                            &:last-child {
                                border-top-right-radius: var(--_row-radius);
                            }
    
                        }
                    }

                    // If there is no option button column (From HK Dashboard)
                    &.no-option-col {
                        td, th {
                            &.fixed {
                                --_first-col-width: 200px;
                                --_second-col-width: 100px;

                                &:nth-child(2) {
                                    box-shadow: 5px 0 10px -5px rgba(0,0,0,.06);
                                }
                            }
    
                            // First non fixed column
                            &.first-scrollable-child {
                                padding-left: 15px !important;
                            }
                        }
                    }
                }
            }

            thead {
                tr {
                    &.header {
                        th {
                            white-space: nowrap;
                            font-weight: bold;
                            color: $default-text-color-dark;
                            text-transform: uppercase;
                            font-size: 13px;
                            padding: 10px;
                            border-bottom: 2px solid $default-theme-color;
                            background-color: lighten($default-theme-color, 40%) !important;
                            position: sticky !important;
                            top: 0; // Stick on top
                            z-index: 1;

                            &.fixed {
                                z-index: 2;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    &.body-row {
                        td {
                            white-space: break-spaces;
                            color: $default-text-color;

                            &:first-child {
                                border-bottom-left-radius: var(--_row-radius);
                            }
                            &:last-child {
                                border-bottom-right-radius: var(--_row-radius);
                            }

                            .status {
                                background-color: transparentize($default-text-color-light, 0.9);
                                border: 1px solid transparentize($default-text-color-light, 0.9);
                                padding: 5px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 5px;
                                color: $default-text-color;
                                font-weight: bold;

                                &.confirmed {
                                    background-color: transparentize($default-green-color, 0.9);
                                    border-color: transparentize($default-green-color, 0.9);
                                    color: $default-green-color;
                                }
                                &.cancelled {
                                    background-color: transparentize($default-red-color, 0.9);
                                    border-color: transparentize($default-red-color, 0.9);
                                    color: $default-red-color;
                                }
                                &.provisional {
                                    background-color: transparentize($default-blue-color, 0.9);
                                    border-color: transparentize($default-blue-color, 0.9);
                                    color: $default-blue-color;
                                }
                                &.wait-listed {
                                    background-color: transparentize($default-yellow-color, 0.9);
                                    border-color: transparentize($default-yellow-color, 0.9);
                                    color: $default-yellow-color;
                                }
                            }

                            &.hall-name {
                                font-size: clamp(1rem, 2vw, 1.15rem);
                                font-weight: 500;
                                color: $default-text-color-dark;
                                overflow-wrap: break-word;
                            }
                        }

                        &.vacant-hall {
                            td {
                                padding: 11px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}