//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Login Page styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";


section {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    height: 100vh;
    margin: 0;
    z-index: 100;

    .as-login-container {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 20px 20px 40px darken(#ffffff, 30%), 
                    -20px -20px 40px #ffffff;
        position: relative;
        overflow: hidden;
        width: 768px;
        max-width: 100%;
        min-height: 480px;

        .as-form-container {
            position: absolute;
            top: 0;
            height: 100%;
            transition: all 0.6s ease-in-out;

            &.as-reset-password-form-container {
                left: 0;
                width: 50%;
                opacity: 0;
                z-index: 1;
            }

            &.as-login-form-container {
                left: 0;
                width: 50%;
                z-index: 2;
            }

            form {
                &.as-login-form {
                    background-color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0 50px;
                    height: 100%;

                    .as-login-form-control {
                        margin: 0;
                        padding: 5px 0px;
                        width: 100%;

                        &.as-form-logo-top {
                            display: none;

                            table {
                                display: none;
                            }

                        }

                        .as-contact-information {
                            &.small-screen {
                                display: none;
                            }
                        }

                        span {
                            &.as-login-text {
                                font-size: 25px;
                                display: block;
                                font-weight: bolder;
                                color: $default-text-color-dark !important;
                                letter-spacing: unset !important;
                            }
                            &.as-login-info {
                                font-weight: normal;
                                font-size: 12px;
                                color: $default-text-color-light;
                                display: block;
                                padding: 5px 0;
                            }
                        }

                        input {
                            border: none;
                            outline: none;
                            background: transparent;
                            padding: 5px 15px 5px 15px;
                            margin: 10px 0;
                            border-bottom: 1px solid $default-border-color;
                            border-radius: 0;
                            font-size: 12px;
                            width: 100% !important;
                            transition: all 0.4s ease-in-out;
                            color: $default-text-color-dark;

                            &.as-login-error-message {
                                &::placeholder {
                                    color: red !important;
                                }
                                &::-ms-input-placeholder {
                                    color: red !important;
                                }
                                &:-ms-input-placeholder {
                                    color: red !important;
                                }
                            }
                            
                            &.as-input-username {
                                background: url(./../../Icons/user_icon.png) no-repeat;
                                background-position:left;
                                background-size: 12px;
                                padding-left: 25px;
                            }

                            &.as-input-password {
                                background: url(./../../Icons/password_icon.png) no-repeat;
                                background-position:left;
                                background-size: 12px;
                                padding-left: 25px;
                                padding-right: 35px;
                            }

                            &.as-input-email {
                                background: url(./../../Icons/mail_icon.png) no-repeat;
                                background-position:left;
                                background-size: 12px;
                                padding-left: 25px;
                            }

                            &.as-input-otp {
                                background: url(./../../Icons/otp_icon.png) no-repeat;
                                background-position:left;
                                background-size: 12px;
                                padding-left: 25px;
                            }

                            &:hover, &:focus {
                                border-bottom-color: $default-theme-color;
                            }

                        }

                        span {

                            &.as-forgot-password {
                                float: right;
                                text-align: right;
                                font-size: 12px;
                                font-family: $default-font-family;
                                color: $default-text-color-light;
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline;
                                    color: $default-theme-color;
                                }
                            }

                        }

                        button {

                            &.as-login-btn, &.as-logging-in-btn, &.as-reset-password-btn {
                                display: inline-block;
                                padding: 10px 15px;
                                width: 100%;
                                min-width: 100%;
                                max-width: 100%;
                                border-radius: 2px;
                                text-align: center;
                                cursor: pointer; 
                                white-space: nowrap;
                                text-decoration: none;
                                user-select: none;
                                -webkit-font-smoothing: antialiased;
                                -webkit-user-drag: none;
                                -webkit-user-select: none;
                                font-size: 14px;
                                text-transform: capitalize;
                                position: relative;
                                overflow: hidden;
                                border: none;
                                color: #ffffff;
                                background: $default-theme-color;
                                background: linear-gradient(160deg, $default-theme-color, darken($default-theme-color, 5%), darken($default-theme-color, 5%));
                                box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.50);
                                margin: 10px 0 0 0;
                            }

                            &.as-logging-in-btn {
                                cursor: progress;
                            }

                        }

                        .as-login-options {
                            display: block;
                            width: 100%;
                            text-align: center;
                            padding: 20px 0 0 0;
                            
                            a {
                                font-size: 12px;
                                cursor: pointer;
                                text-decoration: none;
                                color: $default-text-color-light;
                                padding: 0 5px;
                                border-right: 1px solid $default-border-color;
            
                                &:last-child {
                                    border: none;
                                }
            
                                &:hover, &:focus {
                                    text-decoration: underline;
                                    color: $default-theme-color;
                                }
                            }
                        }

                        .as-license-info, .as-copyright-info {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            text-align: center;
                            padding: 5px 0 0 0;
                            
                            span {
                                font-size: 12px;
                                color: $default-text-color-light;
                                padding: 0 5px;
                            
                                span {

                                    &.as-aegis-text {
                                        color: $default-theme-color;

                                        &.dark {
                                            color: $default-text-color-dark;
                                        }
                                    }
                                    
                                }
                            
                            }

                            .as-login-page-horizontal-logo {
                                margin-left: 5px;

                                table {
                                    width: 100px;
                                    border: none;
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    padding: 0;
                                    margin: 0;
                                    white-space: nowrap;
                                    table-layout: fixed;

                                    tbody {
                                        tr {
                                            td {
                                                vertical-align: middle;
                                                text-align: left;

                                                svg {
                                                    max-width: 100px;
                                                    height: auto;
                                                    align-self: center;
                                                }
                
                                                span {
                                                    color: $default-text-color-dark;
                                                    font-size: 12px;
                                                    font-weight: bold;
                                                    letter-spacing: 0px;
                                                    font-family: 'Nexa Bold', 'NexaDemo-Bold', 'Roboto';
                                                    margin-bottom: 5px !important;
                                                }

                                            }
                                        }
                                    }
                                }

                            }

                        }

                        .as-copyright-info {
                            padding-top: 10px;
                        }

                        .password-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            position: relative;

                            button {
                                &.toggle-password-btn {
                                    position: absolute;
                                    right: 0;
                                    bottom: 10px;
                                    height: 25px;
                                    width: 25px;
                                    background-color: transparent;
                                    border: 1px solid transparent;
                                    border-radius: 25px;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: $default-text-color-light;

                                    &:hover, &:focus {
                                        background-color: transparentize($default-border-color, 0.5);
                                    }
                                }
                            }
                        }

                    }

                }
            }
            
        }

        .as-aegis-cover-container {
            position: absolute;
            top: 0;
            left: 50%;
            width: 50%;
            height: 100%;
            overflow: hidden;
            transition: transform 0.6s ease-in-out;
            z-index: 20;

            .as-cover-overlay {
                background: $default-body-background-color;
                background: linear-gradient(
                                160deg, 
                                lighten( $default-body-background-color, 10%),
                                $default-body-background-color,
                                darken($default-body-background-color, 5%),
                                darken($default-body-background-color, 10%),
                                darken($default-body-background-color, 15%));
                background: -webkit-linear-gradient(
                                160deg,
                                lighten( $default-body-background-color, 10%),
                                $default-body-background-color,
                                darken($default-body-background-color, 5%),
                                darken($default-body-background-color, 10%)
                                darken($default-body-background-color, 15%));
                background-position: 0 0;
                color: #ffffff;
                position: relative;
                left: -100%;
                height: 100%;
                width: 200%;
                transform: translateX(0);
                transition: transform 0.6s ease-in-out;
                overflow: hidden;

                .as-overlay-display {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0 40px;
                    text-align: center;
                    top: 0;
                    height: 100%;
                    width: 50%;
                    transform: translateX(0);
                    transition: transform 0.6s ease-in-out;

                    &.as-cover-right {
                        right: 0;
                        transform: translateX(0);
                    }

                    &.as-cover-left, &.as-cover-right {
                        .as-aegis-logo {
                            border-radius: 2px;
                            background-color: none;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 100px;
                                height: auto;
                            }

                            svg {
                                width: 200px;
                                height: auto;
                                &.as-software-logo {
                                    path {
                                        fill: $default-text-color-dark !important;
                                        &.highlight {
                                            fill: darken($default-theme-color, 5%) !important;
                                        }
                                    }
                                }
                            }

                        }

                        .as-contact-information {
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            padding: 30px;
                            width: 100%;
                            bottom: 0;
                            gap: 10px;

                            span {
                                &.title {
                                    font-size: 12px;
                                    color: $default-text-color-light;
                                }
                            }

                            .as-contact-details {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                gap: 5px;

                                div {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 10px;

                                    .icon-container {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 5px;

                                        .icon-wrapper {
                                            --icon-wrapper-size: 20px;
                                            --icon-size: 14px;
                                            width: var(--icon-wrapper-size);
                                            height: var(--icon-wrapper-size);
                                            background-color: $default-border-color;
                                            border-radius: var(--icon-wrapper-size);
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            cursor: pointer;
            
                                            .icon {
                                                color: $default-text-color-light;
                                                font-size: var(--icon-size);
                                            }
        
                                            &:hover {
                                                background-color: $default-text-color-light;
                                                .icon {
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }

                                    .value {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 5px;
                                        span {
                                            font-size: 14px;
                                            color: $default-text-color-dark;
                                            cursor: pointer;
                                            color: $default-text-color-dark;
                                            transition: all 0.2s ease-in-out;
                                            &:hover {
                                                color: $default-theme-color;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.as-cover-left {
                        .as-aegis-logo {
                            transform: translateX(0%);
                        }
                    }

                   
                }
            }
        }

        &.as-active-form {

            .as-form-container {

                &.as-reset-password-form-container {
                    transform: translateX(100%);
                    opacity: 1;
                    z-index: 5;
                    animation: show 0.6s;
                }

                &.as-login-form-container {
                    transform: translateX(100%);
                }

            }

            .as-aegis-cover-container {
                transform: translateX(-100%);

                .as-cover-overlay {
                    transform: translateX(50%);

                    .as-overlay-display {

                        .as-cover-left {
                            transform: translateX(0);
                        }
            
                        .as-cover-right {
                            transform: translateX(20%);
                        }

                    }
                }

            }

        }
    }
}


@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}



//  For extra extra small screens with width less than 560px.
@media only screen and (max-width: 560px) {

    section {
        all: unset;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        margin: 0;
        background: #ffffff;
        z-index: $default-login-form-sm-screen-z-index;
        overflow: auto;
    
        .as-login-container {
            all: unset;
    
            .as-form-container {
                position: absolute;
                top: 0;
                height: 100%;
                transition: all 0.6s ease-in-out;
    
                &.as-reset-password-form-container {
                    left: 0;
                    right: 0;
                    width: 100%;
                    z-index: unset;
                }
    
                &.as-login-form-container {
                    left: 0;
                    right: 0;
                    width: 100%;
                    z-index: unset;
                }
    
                form {
                    &.as-login-form {
                        display: inline-block;
                        padding: 20px 25px 0;
                        height: 100%;
                        width: 100%;
    
                        .as-login-form-control {
                            margin: 0;
                            padding: 5px 0px;
                            width: 100%;
    
                            &.as-form-logo-top {
                                display: block;

                                table {
                                    display: table;
                                    table-layout: fixed;
                                    width: 100%;
                                    border: none;
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    padding: 0;
                                    margin: 0 auto 20px;

                                    tbody {
                                        tr {
                                            td {
                                                vertical-align: middle;
                                                padding: 0 5px;
                                                margin: 0;
                                                text-align: center;

                                                svg {
                                                    width: 120px;
                                                    height: auto;
                                                    align-self: center;
                                                    &.as-software-logo {
                                                        path {
                                                            fill: $default-text-color-dark !important;
                                                            &.highlight {
                                                                fill: darken($default-theme-color, 5%) !important;
                                                            }
                                                        }
                                                    }
                                                }
                
                                                span {
                                                    color: $default-text-color-dark;
                                                    font-size: 20px;
                                                    font-weight: bold;
                                                    letter-spacing: 0px;
                                                    font-family: 'Nexa Bold', 'NexaDemo-Bold', 'Roboto';
                                                    margin-bottom: 5px !important;

                                                    span {
                                                        color: $default-theme-color;
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }

                            }

                            .as-contact-information {
                                &.small-screen {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    padding-top: 20px;
                                    gap: 10px;

                                    span {
                                        &.title {
                                            font-size: 12px;
                                            color: $default-text-color-light;
                                        }
                                    }
        
                                    .as-contact-details {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        gap: 5px;
        
                                        div {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 10px;
        
                                            .icon-container {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 5px;
        
                                                .icon-wrapper {
                                                    --icon-wrapper-size: 20px;
                                                    --icon-size: 14px;
                                                    width: var(--icon-wrapper-size);
                                                    height: var(--icon-wrapper-size);
                                                    background-color: transparentize($default-border-color, 0.5);
                                                    border-radius: var(--icon-wrapper-size);
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    cursor: pointer;
                    
                                                    .icon {
                                                        color: $default-text-color-light;
                                                        font-size: var(--icon-size);
                                                    }
                
                                                    &:hover {
                                                        background-color: $default-text-color-light;
                                                        .icon {
                                                            color: #fff;
                                                        }
                                                    }
                                                }
                                            }
        
                                            .value {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 5px;
                                                span {
                                                    font-size: 14px;
                                                    color: $default-text-color-dark;
                                                    cursor: pointer;
                                                    color: $default-text-color-dark;
                                                    transition: all 0.2s ease-in-out;
                                                    &:hover {
                                                        color: $default-theme-color;
                                                        text-decoration: underline;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }
    
                            span {
                                &.as-login-text {
                                    font-size: 20px;
                                    display: block;
                                    font-weight: bolder;
                                    color: $default-text-color-dark !important;
                                }
                                &.as-login-info {
                                    font-weight: normal;
                                    font-size: 12px;
                                    color: $default-text-color-light;
                                    display: block;
                                    padding: 5px 0;
                                }
                            }
    
                            input {
                                padding: 10px 25px;
                                font-size: 14px;
                                
                                &.as-input-username {
                                    background-size: 14px;
                                }
    
                                &.as-input-password {
                                    background-size: 14px;
                                    padding-right: 40px;
                                }
    
                            }
    
                            span {
    
                                &.as-forgot-password {
                                    font-size: 14px;
                                    padding: 10px 0;
                                }
    
                            }
    
                            button {
    
                                &.as-login-btn, &.as-logging-in-btn, &.as-reset-password-btn {
                                    display: inline-block;
                                    padding: 15px 15px;
                                    font-size: 14px;
                                    box-shadow:  0px 4px 10px transparentize($default-theme-color, 0.60);
                                }
    
                            }
    
                            .as-login-options {
                                a {
                                    font-size: 14px;
                                    padding: 0 15px;
                                    border-right: 1px solid transparentize($default-border-color,0.5);
                                }
                            }
    
                            .as-license-info, .as-copyright-info {
                                
                                span {
                                    font-size: small;
                                }
    

                                img {
                                    height: 20px;
                                }

                                .as-login-page-horizontal-logo {

                                    table {
                                        tbody {
                                            tr {
                                                td {
                                                    svg {
                                                        max-width: 16px;
                                                    }
                                                    span {
                                                        font-size: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                }
    
                            }
    
                            .as-copyright-info {
                                padding-top: 10px;
                            }

                            .password-container {
                                button {
                                    &.toggle-password-btn {
                                        bottom: 10px;
                                        height: 35px;
                                        width: 35px;
                                        border-radius: 35px;
                                    }
                                }
                            }
    
                        }
    
                    }
                }
                
            }
    
            .as-aegis-cover-container {
                all: unset;
                display: none;
    
                .as-cover-overlay {
                    all: unset;
                    display: none;
    
                    .as-overlay-display {
                        all: unset;
                        display: none;
    
                        &.as-cover-left, &.as-cover-right {
                            all: unset;
                            display: none;

                            .as-aegis-logo {
                                all: unset;
                                display: none;
    
                                img {
                                    all: unset;
                                    display: none;
                                }
    
                            }
                        }
    
                        &.as-cover-left {
                            all: unset;
                            display: none;

                            .as-aegis-logo {
                                all: unset;
                                display: none;
                            }

                        }
    
                       
                    }
                }
            }

            &.as-active-form {
    
                .as-form-container {

                    &.as-reset-password-form-container {
                        transform: translateX(0%);
                        animation: show1 1s;
                        ~ {
                            .as-login-form-container {
                                display: none;
                            }
                        }
                    }

                    &.as-login-form-container {
                        transform: translateX(100%);
                        ~ {
                            .as-reset-password-form-container {
                                display: none;
                            }
                        }
                    }

                }

            }

            @keyframes show1 {
                0% {
                    opacity: 0;
                }
                
                100% {
                    opacity: 1;
                }
            }
    
        }
    }

}