//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Button styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";
@import "mixins/mixins.scss";

.as-btn{
    @include btnProp;
    border: 1px solid $default-gray-color;
    background-color: transparent;
    color: $default-gray-color;
}

.as-btn-theme{
    @include btnProp;
    border: 1px solid $default-theme-color;
    background-color: $default-theme-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-theme-color, 10%);
    }
}

.as-btn-theme-transparent{
    @include btnProp;
    border: 1px solid $default-theme-color;
    background-color: transparent;
    color: $default-theme-color;
}

.as-btn-blue{
    @include btnProp;
    border: 1px solid $default-blue-color;
    background-color: $default-blue-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-blue-color, 10%);
    }
}

.as-btn-blue-transparent{
    @include btnProp;
    border: 1px solid $default-blue-color;
    background-color: transparent;
    color: $default-blue-color;
}

.as-btn-green{
    @include btnProp;
    border: 1px solid $default-green-color;
    background-color: $default-green-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-green-color, 10%);
    }
}

.as-btn-green-transparent{
    @include btnProp;
    border: 1px solid $default-green-color;
    background-color:transparent;
    color: $default-green-color;
}

.as-btn-yellow{
    @include btnProp;
    border: 1px solid $default-yellow-color;
    background-color: $default-yellow-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-yellow-color, 10%);
    }
}

.as-btn-yellow-transparent{
    @include btnProp;
    border: 1px solid $default-yellow-color;
    background-color:transparent;
    color: $default-yellow-color;
}

.as-btn-red{
    @include btnProp;
    border: 1px solid $default-red-color;
    background-color: $default-red-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-red-color, 10%);
    }
}

.as-btn-red-transparent{
    @include btnProp;
    border: 1px solid $default-red-color;
    background-color:transparent;
    color: $default-red-color;
}

.as-btn-light-blue{
    @include btnProp;
    border: 1px solid $default-light-blue-color;
    background-color: $default-light-blue-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-light-blue-color, 10%);
    }
}

.as-btn-light-blue-transparent{
    @include btnProp;
    border: 1px solid $default-light-blue-color;
    background-color:transparent;
    color: $default-light-blue-color;
}

.as-btn-gray{
    @include btnProp;
    border: 1px solid $default-gray-color;
    background-color:$default-gray-color;
    color: #ffffff;
    &:hover {
        background-color: darken($default-gray-color, 10%);
    }
}

.as-btn-round{
    padding: 0;
    width:25px;
    height:25px;
    border-radius:50%;
    outline: none;
}
 
.as-btn-small {
    padding: 8px 12px;
    font-size: .8rem;
}

.as-btn-round-small {
    width: 35px;
    height: 35px;
    font-size: .65rem;
}

.as-btn-borderless {
    border: none;
    cursor: pointer;
    outline: none;
}

.as-btn-borderless:hover{
    background-color: transparentize(#000000, 0.95);
}


//  Dropdown Buttons (Used only for icon buttons)
.as-topmenubar-dropbtn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    &:hover, &:focus {
        background-color: #f1f1f1;
        outline: none;
    }
}


//  Radio Button Group
.as-radio-group{
    display: inline-flex !important;
    overflow: hidden !important;
    border: 1px solid $default-border-color;
    border-radius: 20px !important;
    padding: 0 !important;
}

.as-radio-group-btn{
    display: none !important;
    &:checked {
        + .as-radio-group-label {
            box-shadow: inset 6px 6px 13px #909090, 
            inset -6px -6px 13px #e6e6e6;
        }
        + .as-radio-group-label-theme,
        + .as-radio-group-label-blue,
        + .as-radio-group-label-green,
        + .as-radio-group-label-yellow,
        + .as-radio-group-label-red,
        + .as-radio-group-label-light-blue
         {
            box-shadow: inset 6px 6px 13px transparentize(#000000, 0.4), 
            inset -6px -6px 13px transparentize(#000000, 0.8);
        }
    }
}

.as-radio-group-label{
    margin: 0 !important;
    padding: 8px 12px !important;
    background: #ffffff !important;
    background: linear-gradient(to bottom, #ffffff 0%, rgba(230,230,230,1) 100%) !important;
    color: transparentize(#000000, 0.15) !important;
    transition: all 0.2s ease-in-out !important;
    cursor: pointer !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-border-color;
    }

    &.small {
        padding: 5px 10px !important;
    }
}

.as-radio-group-label-theme {
    background: $default-theme-color !important;
    background: linear-gradient(to bottom, $default-theme-color 0%, darken($default-theme-color, 10%) 100%) !important;
    color: #ffffff !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-theme-color;
    }
}

.as-radio-group-label-blue {
    background: $default-blue-color !important;
    background: linear-gradient(to bottom, $default-blue-color 0%, darken($default-blue-color, 10%) 100%) !important;
    color: #ffffff !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-blue-color;
    }
}

.as-radio-group-label-green {
    background: $default-green-color !important;
    background: linear-gradient(to bottom, $default-green-color 0%, darken($default-green-color, 10%) 100%) !important;
    color: #ffffff !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-green-color;
    }
}

.as-radio-group-label-yellow {
    background: $default-yellow-color !important;
    background: linear-gradient(to bottom, $default-yellow-color 0%, darken($default-yellow-color, 10%) 100%) !important;
    color: #ffffff !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-yellow-color;
    }
}

.as-radio-group-label-red {
    background: $default-red-color !important;
    background: linear-gradient(to bottom, $default-red-color 0%, darken($default-red-color, 10%) 100%) !important;
    color: #ffffff !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-red-color;
    }
}

.as-radio-group-label-light-blue {
    background: $default-light-blue-color !important;
    background: linear-gradient(to bottom, $default-light-blue-color 0%, darken($default-light-blue-color, 10%) 100%) !important;
    color: #ffffff !important;
    &:not(:last-of-type) {
        border-right: 1px solid $default-light-blue-color;
    }
}


//  Used in POS Take Order page.
.as-radio-group-transparent{
    display: inline-flex !important;
    overflow: hidden !important;
    border: none;
    padding: 0 !important;

    input {
        &.as-radio-group-btn{
            display: none !important;
            
            &:checked {
                + label {
                    &.as-radio-group-label {
                        background: #ffffff !important;
                        color: $default-theme-color !important;
                        box-shadow: none;
                        &.yellow {
                            color: $default-yellow-color !important;
                        }
                        &.red {
                            color: $default-red-color !important;
                        }
                        &.green {
                            color: $default-green-color !important;
                        }
                        &.blue {
                            color: $default-blue-color !important;
                        }
                        &.light-blue {
                            color: $default-light-blue-color !important;
                        }
                        &.light {
                            color: $default-text-color-light !important;
                        }
                        &.dark {
                            color: $default-text-color-dark !important;
                        }
                        &.normal {
                            color: $default-text-color !important;
                        }
                    }
                }
            }
        }
    }

    .as-radio-group-label {
        margin: 0 !important;
        border-radius: 5px;
        padding: 5px 10px !important;
        background: transparent !important;
        color: $default-text-color-light !important  ;
        transition: all 0.2s ease-in-out !important;
        cursor: pointer !important;
        &:not(:last-of-type) {
            border: none;
        }
    }

    &.light-background {
        input {
            &.as-radio-group-btn{
                display: none !important;
                
                &:checked {
                    + label {
                        &.as-radio-group-label {
                            background: $default-body-background-color !important;
                            color: $default-text-color-dark !important;
                            &.yellow {
                                color: $default-yellow-color !important;
                            }
                            &.red {
                                color: $default-red-color !important;
                            }
                            &.green {
                                color: $default-green-color !important;
                            }
                            &.blue {
                                color: $default-blue-color !important;
                            }
                            &.light-blue {
                                color: $default-light-blue-color !important;
                            }
                            &.light {
                                color: $default-text-color-light !important;
                            }
                            &.dark {
                                color: $default-text-color-dark !important;
                            }
                            &.normal {
                                color: $default-text-color !important;
                            }
                        }
                    }
                }
            }
        }

        &.green {
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            &.as-radio-group-label {
                                background: transparentize($default-green-color, 0.9) !important;
                            }
                        }
                    }
                }
            }
            &.with-label {
                input {
                    &.as-radio-group-btn{
                        &:checked {
                            + label {
                                &.as-radio-group-label {
                                    color: darken($default-green-color, 10%) !important;
                                }
                            }
                        }
                    }
                }   
            }
        }
        &.red {
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            &.as-radio-group-label {
                                background: transparentize($default-red-color, 0.9) !important;
                            }
                        }
                    }
                }
            }
            &.with-label {
                input {
                    &.as-radio-group-btn{
                        &:checked {
                            + label {
                                &.as-radio-group-label {
                                    color: darken($default-red-color, 10%) !important;
                                }
                            }
                        }
                    }
                }   
            }
        }
        &.yellow {
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            &.as-radio-group-label {
                                background: transparentize($default-yellow-color, 0.9) !important;
                            }
                        }
                    }
                }
            }
            &.with-label {
                input {
                    &.as-radio-group-btn{
                        &:checked {
                            + label {
                                &.as-radio-group-label {
                                    color: darken($default-yellow-color, 10%) !important;
                                }
                            }
                        }
                    }
                }   
            }
        }
        &.blue {
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            &.as-radio-group-label {
                                background: transparentize($default-blue-color, 0.9) !important;
                            }
                        }
                    }
                }
            }
            &.with-label {
                input {
                    &.as-radio-group-btn{
                        &:checked {
                            + label {
                                &.as-radio-group-label {
                                    color: darken($default-blue-color, 10%) !important;
                                }
                            }
                        }
                    }
                }   
            }
        }
        &.purple {
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            &.as-radio-group-label {
                                background: transparentize($default-purple-accent, 0.9) !important;
                            }
                        }
                    }
                }
            }
            &.with-label {
                input {
                    &.as-radio-group-btn{
                        &:checked {
                            + label {
                                &.as-radio-group-label {
                                    color: darken($default-purple-accent, 10%) !important;
                                }
                            }
                        }
                    }
                }   
            }
        }
    }

    &.as-non-white-bg {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        input {
            &.as-radio-group-btn{
                
                &:checked {
                    + label {
                        &.as-radio-group-label {
                            color: transparentize(#ffffff, 0) !important;
                            background-color: $default-theme-color !important;

                            &.small {
                                padding: 2px 5px !important;
                                font-size: 11px !important;
                            }

                            .icon {
                                color: transparentize(#ffffff, 0) !important;
                            }
                            
                        }
                        small {
                            color: transparentize($default-body-background-color, 0) !important;
                        }
                    }
                }
            }
        }
    
        .as-radio-group-label {
            background: transparent !important;
            color: $default-text-color-dark !important;
            cursor: pointer !important;

            &.small {
                padding: 2px 5px !important;
                font-size: 11px !important;
            }

        }

    }

    &.as-non-white-body-bg {
        input {
            &.as-radio-group-btn{
                
                &:checked {
                    + label {
                        &.as-radio-group-label {
                            color: #fff !important;
                            background-color: $default-text-color-light !important;

                            &.small {
                                padding: 2px 5px !important;
                                font-size: 11px !important;
                            }
                            
                        }
                    }
                }
            }
        }
    
        .as-radio-group-label {
            background: transparent !important;
            color: $default-text-color !important;
            cursor: pointer !important;

            &.small {
                padding: 2px 5px !important;
                font-size: 11px !important;
            }

        }
    }

    &.small {
        input {
            &.as-radio-group-btn{
                
                &:checked {
                    + label {
                        &.as-radio-group-label {
                            padding: 2px 8px !important;
                            font-size: 10px !important;

                            &.small {
                                padding: 2px 8px !important;
                                font-size: 10px !important;
                            }
                            
                        }
                    }
                }
            }
        }
    
        .as-radio-group-label {
            padding: 2px 8px !important;
            font-size: 10px !important;

            &.small {
                padding: 2px 8px !important;
                font-size: 10px !important;
            }

        }
    }

    &.as-selection-btn {
        
        input {
            &.as-radio-group-btn{
                
                &:checked {
                    + label {
                        &.as-radio-group-label {
                            background: lighten($default-menu-category-highlight-color, 30%) !important;
                            color: $default-text-color-dark !important;
                            box-shadow: 1px 2px 5px transparentize($default-text-color, 0.5) !important;

                            .as-icon {
                                border: 1px solid transparentize($default-menu-category-highlight-color, 0.2) !important;
                                background: #fff !important;
                            }

                            svg {
                                &.as-icon {
                                    border: 1px solid transparentize($default-menu-category-highlight-color, 0.2) !important;
                                    background: #fff !important;
                                }
                            }

                        }
                    }
                }
            }
        }
    
        .as-radio-group-label {
            margin: 2px 10px 2px 2px !important;
            padding: 6px 15px 6px 10px !important;
            font-size: 12px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff !important;
            color: $default-text-color !important;
            border-radius: 10px !important;
            box-shadow: 1px 2px 5px transparentize($default-border-color, 0.5) !important;

            .as-icon {
                margin-right: 10px;
                border-radius: 50%;
                font-size: 40px;
                border: 1px solid transparentize($default-border-color, 0.5) !important;
                padding: 8px;
                height: 40px;
                width: 40px;
                color: lighten($default-menu-category-highlight-color, 15%) !important;
                background: transparentize($default-menu-category-highlight-color, 0.9) !important;
            }

            svg {
                &.as-icon {
                    margin-right: 10px !important;
                    border-radius: 50% !important;
                    font-size: 40px !important;
                    border: 1px solid transparentize($default-border-color, 0.5) !important;
                    padding: 8px !important;
                    height: 40px !important;
                    width: 40px !important;
                    color: lighten($default-menu-category-highlight-color, 15%) !important;
                    background: transparentize($default-menu-category-highlight-color, 0.9) !important;
                    path, circle, rect, line, polygon, ellipse {
                        fill: lighten($default-menu-category-highlight-color, 15%) !important;
                    }
                }
            }

        }

        &.full-width {
            .as-radio-group-label {
                margin: 4px 10px 4px 4px !important;
            }
        }

        //  For Attendant
        &.as-attendant {
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            &.as-radio-group-label {
                                background: lighten($default-attendant-highlight-color, 30%) !important;
                                .as-icon {
                                    border: 1px solid transparentize($default-attendant-highlight-color, 0.2) !important;
                                }
                                svg {
                                    &.as-icon {
                                        border: 1px solid transparentize($default-attendant-highlight-color, 0.2) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .as-radio-group-label {
                margin: 2px 6px 2px 2px !important;
                padding: 4px 15px 4px 10px !important;
                font-size: 12px !important;
                .as-icon {
                    color: lighten($default-attendant-highlight-color, 15%) !important;
                    background: transparentize($default-attendant-highlight-color, 0.9) !important;
                }
                svg {
                    &.as-icon {
                        margin-right: 10px !important;
                        border-radius: 50% !important;
                        font-size: 35px !important;
                        border: 1px solid transparentize($default-border-color, 0.5) !important;
                        padding: 7px !important;
                        height: 35px !important;
                        width: 35px !important;
                        color: lighten($default-attendant-highlight-color, 15%) !important;
                        background: transparentize($default-attendant-highlight-color, 0.9) !important;
                        path, circle, rect, line, polygon, ellipse {
                            fill: lighten($default-attendant-highlight-color, 15%) !important;
                        }
                    }
                }
    
            }
        }
        
    }

}


//  Ripple Effect
.as-show-ripple{
    position: absolute;
    background: transparentize(#ffffff,0.6);
    border-radius: 50%;
    width: 5px;
    height: 5px;
    -webkit-animation: rippleEffect 1.4s 1;
    animation: rippleEffect 1.4s 1;
    opacity: 1;
}

@-webkit-keyframes rippleEffect {
    0% {
        -webkit-transform:scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform:scale(100);
        opacity: 0;
    }
}

@keyframes rippleEffect {
    0% {
        transform:scale(1);
        opacity: 1;
    }
    100% {
        transform:scale(100);
        opacity: 0;
    }
}


//  Badge
.as-badge{
    padding: 3px 10px;
    border: 0;
    border-radius: 10px;
    font-weight: 500;
    font-size: small;
    background-color: $default-gray-color;
    color: #ffffff;

    &.theme {
        background-color: transparentize($default-theme-color, 0.84);
        color: $default-theme-color;
    }

    &.green {
        background-color: transparentize($default-green-color, 0.84);
        color: $default-green-color;
    }

    &.blue {
        background-color: transparentize($default-blue-color, 0.84);
        color: $default-blue-color;
    }

    &.red {
        background-color: transparentize($default-red-color, 0.84);
        color: $default-red-color;
    }

    &.yellow {
        background-color: transparentize($default-yellow-color, 0.84);
        color: $default-yellow-color;
    }

    &.light {
        background-color: transparentize($default-text-color-light, 0.84);
        color: $default-text-color-light;
    }

}



//  Default Buttons
button {

    //  Disabled Button
    &:disabled {
        color: $default-text-color-light !important;
        border: 1px solid $default-border-color !important;
        background: transparent !important;
        cursor: not-allowed !important;
        box-shadow: none !important;

        svg {
            fill: $default-text-color-light !important;
            path {
                fill: $default-text-color-light !important;
            }
        }

        &:hover, &:focus {
            color: $default-text-color-light !important;
            border: 1px solid $default-border-color !important;
            background: transparent !important;

            svg {
                fill: $default-text-color-light !important;
                path {
                    fill: $default-text-color-light !important;
                }
            }

        }

    }

    //  Solid Buttons
    &.as-default-btn-solid {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer; 
        white-space: nowrap;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-size: $default-font-size;
        letter-spacing: 0.25px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        color: #ffffff;
        background: $default-theme-color;
        background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);
        margin: 0 5px;

        &:disabled {
            color: $default-text-color !important;
            border: 2px solid $default-border-color !important;
            background: $default-border-color !important;
            cursor: not-allowed !important;

            svg {
                fill: $default-text-color !important;
                path {
                    fill: $default-text-color !important;
                }
            }

            &:hover, &:focus {
                color: $default-text-color !important;
                border: 2px solid $default-border-color !important;
                background: $default-border-color !important;

                svg {
                    fill: $default-text-color !important;
                    path {
                        fill: $default-text-color !important;
                    }
                }

            }

        }

    }

    &.as-default-btn-solid-red {
        @extend .as-default-btn-solid;
        border: 2px solid $default-red-color;
        background: $default-red-color;
        background: linear-gradient(140deg, lighten($default-red-color, 5%), $default-red-color, darken($default-red-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-red-color, 0.6);
    }

    &.as-default-btn-solid-green {
        @extend .as-default-btn-solid;
        border: 2px solid $default-green-color;
        background: $default-green-color;
        background: linear-gradient(140deg, lighten($default-green-color, 5%), $default-green-color, darken($default-green-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-green-color, 0.6);
        outline-color: $default-theme-color !important;
    }

    &.as-default-btn-solid-yellow {
        @extend .as-default-btn-solid;
        border: 2px solid $default-yellow-color;
        background: $default-yellow-color;
        background: linear-gradient(140deg, lighten($default-yellow-color, 5%), $default-yellow-color, darken($default-yellow-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-yellow-color, 0.6);
    }

    &.as-default-btn-solid-blue {
        @extend .as-default-btn-solid;
        border: 2px solid $default-blue-color;
        background: $default-blue-color;
        background: linear-gradient(140deg, lighten($default-blue-color, 5%), $default-blue-color, darken($default-blue-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-blue-color, 0.6);
    }

    &.as-default-btn-solid-light-blue {
        @extend .as-default-btn-solid;
        border: 2px solid $default-light-blue-color;
        background: $default-light-blue-color;
        background: linear-gradient(140deg, lighten($default-light-blue-color, 5%), $default-light-blue-color, darken($default-light-blue-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-light-blue-color, 0.6);
    }

    &.as-default-btn-solid-gray {
        @extend .as-default-btn-solid;
        border: 2px solid $default-gray-color;
        background: $default-gray-color;
        background: linear-gradient(140deg, lighten($default-gray-color, 5%), $default-gray-color, darken($default-gray-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-gray-color, 0.6);
    }

    &.as-default-btn-solid-light {
        @extend .as-default-btn-solid;
        border: 2px solid $default-text-color-light;
        background: $default-text-color-light;
        background: linear-gradient(140deg, lighten($default-text-color-light, 5%), $default-text-color-light, darken($default-text-color-light, 5%));
        box-shadow:  0px 4px 8px transparentize($default-text-color-light, 0.6);
    }

    &.as-default-btn-solid-dark {
        @extend .as-default-btn-solid;
        border: 2px solid $default-text-color-dark;
        background: $default-text-color-dark;
        background: linear-gradient(140deg, lighten($default-text-color-dark, 5%), $default-text-color-dark, darken($default-text-color-dark, 5%));
        box-shadow:  0px 4px 8px transparentize($default-text-color-dark, 0.6);
    }

    //  Transparent Buttons
    &.as-default-btn-transparent {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-size: $default-font-size;
        letter-spacing: 0.25px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        color: $default-theme-color;
        background: transparent;
        margin: 0 5px;

        &:disabled {
            color: $default-text-color-light !important;
            border: 2px solid $default-border-color !important;
            cursor: not-allowed !important;

            svg {
                fill: $default-text-color-light !important;
                path {
                    fill: $default-text-color-light !important;
                }
            }

            &:hover, &:focus {
                color: $default-text-color-light !important;
                border: 2px solid $default-border-color !important;
                background: transparent !important;

                svg {
                    fill: $default-text-color-light !important;
                    path {
                        fill: $default-text-color-light !important;
                    }
                }

            }

        }
    }

    &.as-default-btn-transparent-red {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-red-color;
        color: $default-red-color;
    }

    &.as-default-btn-transparent-green {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-green-color;
        color: $default-green-color;
    }

    &.as-default-btn-transparent-yellow {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-yellow-color;
        color: $default-yellow-color;
    }

    &.as-default-btn-transparent-blue {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-blue-color;
        color: $default-blue-color;
    }

    &.as-default-btn-transparent-light-blue {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-light-blue-color;
        color: $default-light-blue-color;
    }

    &.as-default-btn-transparent-gray {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-gray-color;
        color: $default-gray-color;
    }

    &.as-default-btn-transparent-light {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-text-color-light;
        color: $default-text-color-light;
    }

    &.as-default-btn-transparent-dark {
        @extend .as-default-btn-transparent;
        border: 2px solid $default-text-color-dark;
        color: $default-text-color-dark;
    }

    &.as-default-btn-large {
        padding: 8px 12px;
    }
    
    &.as-default-btn-x-large {
        padding: 12px 20px;
        font-size: 16px;
    }

    &.as-default-btn-for-popup{
        display: inline-block;
        height: 15px;
        width: 15px;
        padding: 0px;
        margin: 0 5px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        color: #ffffff;
        background: $default-theme-color;
        background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
        box-shadow:  1px 2px 4px transparentize(#000000, 0.8);
        svg {
            font-size: 10px;
        }
    }

    &.as-default-btn-transparent-small-icon{
        display: inline-block;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        font-size: $default-font-size;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 50%;
        color: $default-theme-color;
        background: transparent;
        margin: 0 5px;
        width: 30px;
        height: 30px;
        line-height: 0;
        padding: 0px;

        &:hover, &:focus {
            background: transparentize($default-border-color, 0.96);
            box-shadow: 0px 1px 6px transparentize(#000000, 0.8);
        }

        &.x-small {
            font-size: 12px;
            margin: 0;
            width: 22px;
            height: 22px;
            padding: 0px;
        }

        &.light {
            color: $default-text-color-light;
        }

        &.red {
            color: $default-red-color;
        }

        &.bordered {
            border-color: $default-theme-color;
            &.light {
                border-color: $default-text-color-light;
            }
    
            &.red {
                border-color: $default-red-color;
            }
        }

        &.for-touch {
            width: 35px;
            height: 35px;
        }
        
    }

    &.as-default-btn-solid-small-icon{
        display: inline-block;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        font-size: $default-font-size;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 50%;
        color: #ffffff;
        background: $default-theme-color;
        margin: 0 5px;
        width: 30px;
        height: 30px;
        line-height: 0;
        padding: 0px;

        &:hover, &:focus {
            background: darken($default-theme-color, 5%);
            box-shadow: 0px 1px 6px transparentize(#000000, 0.7);
        }

        &.green {
            background: $default-green-color;
            &:hover, &:focus {
                background: darken($default-green-color, 5%);
            }
        }

        &.red {
            background: $default-red-color;
            &:hover, &:focus {
                background: darken($default-red-color, 5%);
            }
        }

        &.x-small {
            font-size: 12px;
            margin: 0;
            width: 22px;
            height: 22px;
            padding: 0px;
        }
        
    }

    &.as-transparent-btn-large {
        display: inline-block;
        height: 180px;
        width: 150px;
        padding: 0px;
        margin: 15px;
        text-align: center;
        text-align: -webkit-center;
        border-radius: 10px;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        color: $default-theme-color;
        background: transparent;
        box-shadow:  0px 2px 10px transparentize($default-border-color, 0.5);
        transition: all 0.2s ease-in-out;

        &:disabled {
            &:hover, &:focus {
                svg {
                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-text-color-light !important;
                    }
                }
    
            }
        }

        &:hover, &:focus {
            outline: none;
            color: #ffffff;
            background: $default-theme-color;
            background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
            box-shadow:  0px 2px 10px transparentize($default-theme-color, 0.5);

            svg {
                path, circle, rect, line, polygon, ellipse {
                    fill: #ffffff !important;
                }
            }

        }

        svg {
            width: 100px;
            height: auto;
        }

        span {
            &.as-btn-text {
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                display: block;
                padding-top: 10px;
            }
        }
    }

    &.as-btn-text {
        padding: 5px 10px;
        font-size: 12px;
        height: 26px;
        border: 2px solid transparent;
        border-radius: 13px;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        position: relative;
        overflow: hidden;
        color: $default-theme-color;
        background: transparent;
        box-shadow:  none;

        &:focus {
            border-color: transparent;
        }

        &.small {
            height: auto;
            padding: 2px;
            border-radius: 50%;
        }

        &.with-border {
            border-color: $default-theme-color;
            height: unset;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }

    &.as-btn-text-red {
        @extend .as-btn-text;
        color: $default-red-color;
        &.with-border {
            border-color: $default-red-color;
        }
    }

    &.as-btn-text-green {
        @extend .as-btn-text;
        color: $default-green-color;
        &.with-border {
            border-color: $default-green-color;
        }
    }

    &.as-btn-text-yellow {
        @extend .as-btn-text;
        color: $default-yellow-color;
        &.with-border {
            border-color: $default-yellow-color;
        }
    }

    &.as-btn-text-blue {
        @extend .as-btn-text;
        color: $default-blue-color;
        &.with-border {
            border-color: $default-blue-color;
        }
    }

    &.as-btn-text-light-blue {
        @extend .as-btn-text;
        color: $default-light-blue-color;
        &.with-border {
            border-color: $default-light-blue-color;
        }
    }

    &.as-btn-text-normal {
        @extend .as-btn-text;
        color: $default-text-color;
        &.with-border {
            border-color: $default-text-color;
        }
    }

    &.as-btn-text-light {
        @extend .as-btn-text;
        color: $default-text-color-light;
        &.with-border {
            border-color: $default-text-color-light;
        }
    }

    &.as-btn-text-dark {
        @extend .as-btn-text;
        color: $default-text-color-dark;
        &.with-border {
            border-color: $default-text-color-dark;
        }
    }

    //  Open Outlet Button
    &.as-open-outlet-btn {
        display: block;
        width: 140px;
        padding: 10px;
        margin: 15px;
        margin-top: 0;
        text-align: center;
        text-align: -webkit-center;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-border-color;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: #ffffff;
        outline: none;
        background: $default-border-color;
        box-shadow: 5px 5px 10px transparentize($default-border-color, 0.9);
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
            border-color: $default-text-color-dark;
            background: $default-text-color-dark;
            box-shadow:  5px 5px 10px transparentize($default-text-color-dark, 0.9);
        }

    }

    //  Close Outlet Button
    &.as-close-outlet-btn, &.as-clock-in-btn, &.as-clock-out-btn, &.as-go-to-order-btn {
        display: block;
        width: 140px;
        padding: 10px;
        margin: 15px;
        margin-top: 0;
        text-align: center;
        text-align: -webkit-center;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: #ffffff;
        outline: none;
        background: $default-theme-color;
        box-shadow: 5px 5px 10px transparentize($default-theme-color, 0.9);
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
            border-color: $default-text-color-dark;
            background: $default-text-color-dark;
            box-shadow:  5px 5px 10px transparentize($default-text-color-dark, 0.9);
        }

    }

    &.as-clock-in-btn {
        filter: grayscale(1) !important;
    }

    &.as-clock-out-btn {
        filter: grayscale(0) !important;
    }

    //  Default Outlet Button (Closed Outlet)
    &.as-outlet-btn {
        display: block;
        height: 180px;
        width: 140px;
        padding: 5px;
        margin: 15px;
        text-align: center;
        text-align: -webkit-center;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-border-color;
        color: $default-border-color;
        background: transparent;
        filter: grayscale(1);
        box-shadow:  5px 5px 10px transparentize($default-border-color, 0.9);
        transition: all 0.2s ease-in-out;

        //  Outlet Logo
        .as-outlet-cover {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 5px;
            overflow: hidden;

            img {
                &.as-outlet-logo {
                    height: auto;
                    width: 100%;
                }
            }

            svg {
                &.as-local-svg {
                    height: auto;
                    width: 100%;
                }
            }

        }

        //  Outlet Icon
        .as-outlet-icon {
            font-size: 40px;
            color: $default-border-color;
        }

        
        ~ {

            //  Outlet Name
            span {
                &.as-outlet-name {
                    color: $default-text-color-light;
                    filter: grayscale(1) !important;
                    transition: all .2s ease-in-out;
                }
            }
        } 

        &:hover, &:focus {
            outline: none;
            border-color: $default-text-color-dark;
            color: $default-text-color-dark;
            box-shadow:  5px 5px 10px transparentize($default-text-color-dark, 0.9);

            ~ {
                //  Outlet Name
                span {
                    &.as-outlet-name {
                        color: $default-text-color-dark;
                        filter: grayscale(0) !important;
                        transition: all .2s ease-in-out;
                    }
                }
            } 

            .as-outlet-icon {
                color: $default-text-color-dark;
            }

        }

    }

    //  Opened Outlet Button
    &.as-outlet-btn-opened {
        display: block;
        height: 180px;
        width: 140px;
        padding: 5px;
        margin: 15px;
        text-align: center;
        text-align: -webkit-center;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        color: $default-theme-color;
        background: transparent;
        filter: grayscale(0);
        box-shadow:  5px 5px 10px transparentize($default-theme-color, 0.9);
        transition: all 0.2s ease-in-out;

        //  Outlet Logo
        .as-outlet-cover {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 5px;
            overflow: hidden;

            img {
                &.as-outlet-logo {
                    height: auto;
                    width: 100%;
                }
            }

            svg {
                &.as-local-svg {
                    height: auto;
                    width: 100%;
                }
            }
            
        }

        //  Outlet Icon
        .as-outlet-icon {
            font-size: 40px;
            color: $default-theme-color;
        }

        ~ {

            //  Outlet Name
            span {
                &.as-outlet-name {
                    color: $default-theme-color;
                    filter: grayscale(0) !important;
                    transition: all .2s ease-in-out;
                }
            }
        } 

        &:hover, &:focus {
            outline: none;
            border-color: $default-text-color-dark;
            color: $default-text-color-dark;
            box-shadow:  5px 5px 10px transparentize($default-text-color-dark, 0.9);

            .as-outlet-icon {
                color: $default-text-color-dark;
            }

            ~ {

                //  Outlet Name
                span {
                    &.as-outlet-name {
                        color: $default-text-color-dark;
                        filter: grayscale(0) !important;
                        transition: all .2s ease-in-out;
                    }
                }
            }

        }

    }

    //  Clocked In/Out Button
    &.as-clocked-in-btn, &.as-clocked-out-btn {
        padding-top: 25px;
        height: 200px;

        .as-clock-in-out-div {
            text-align: left;
            padding: 5px 10px;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            background-color: transparentize($color: #ffffff, $amount: 0.5);
            width: 100%;

            .as-clocked-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 2px;
                font-size: 12px;
                font-weight: 600;
            }

            span {
                &.as-clock-in-out-text {
                    display: block;
                    white-space: nowrap;
                    padding: 0;
                    padding-bottom: 2px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        &:hover, &:focus {
            ~ {

                //  Outlet Name
                span {
                    &.as-outlet-name {
                        color: $default-text-color-dark;
                        filter: grayscale(0) !important;
                    }
                }
            }
        }

    }

    &.as-clocked-in-btn {
        filter: grayscale(1) !important;
        ~ {

            //  Outlet Name
            span {
                &.as-outlet-name {
                    filter: grayscale(1) !important;
                    transition: all .2s ease-in-out;
                }
            }
        }
    }

    &.as-clocked-out-btn {
        filter: grayscale(0) !important;
        ~ {

            //  Outlet Name
            span {
                &.as-outlet-name {
                    filter: grayscale(0) !important;
                    transition: all .2s ease-in-out;
                }
            }
        }
    }

    //  Go to order via touch screen button
    &.as-go-to-touch-order-btn {
        display: block;
        padding: 8px 14px;
        border-radius: 20px;
        text-align: center;
        cursor: pointer; 
        white-space: nowrap;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-size: $default-font-size;
        letter-spacing: 0.25px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid $default-theme-color;
        color: #ffffff;
        background: $default-theme-color;
        background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
        box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);
        margin: 10px 5px 15px;
    }

    //  POS Order Page Table Buttons Inside Tabs
    &.as-table-btn {
        height: 160px;
        width: 100%;
        padding: 0px;
        // margin: 5px 10px;
        text-align: center;
        text-align: -webkit-center;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 1px solid transparentize($color: $default-border-color, $amount: 0.5);
        border-radius: 15px;
        color: $default-border-color;
        background: #fff;
        box-shadow: 4px 4px 8px transparentize($default-border-color, 0.6);
        transition: all 0.2s ease-in-out;

        .as-table-icon {
            font-size: 25px;
            display: block;
            margin-bottom: 10px;
            color: $default-border-color;
        }

        svg {
            &.as-local-svg-table {
                height: 30px;

                path, circle, rect, line, polygon, ellipse {
                    fill: $default-text-color-light !important;
                    &.as-table-cover {
                        fill: transparentize($default-green-color, 0.5) !important;
                    }
                    
                }

            }

        }

        .hold-icon {
            --_hold-icon-size: 25px;
            position: absolute;
            top: 60%;
            left: 25%;
            transform: translateY(-60%);
            width: var(--_hold-icon-size);
            height: var(--_hold-icon-size);
            border: 2px solid #fff;
            border-radius: var(--_hold-icon-size);
            margin: 0;
            padding: 0;
            background-color: transparentize($default-yellow-color, 0.15);
            color: #fff;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span {
            
            &.as-table-total-cover {
                position: absolute;
                top: 15px;
                left: 10px;
                font-size: x-small;
                display: block;
                color: $default-text-color;
            }

            &.as-table-time-taken {
                position: absolute;
                top: 25px;
                left: 10px;
                font-size: x-small;
                display: block;
                color: $default-text-color;
            }

            &.as-merged-icon {
                position: absolute;
                bottom: 35px;
                right: 8px;
                font-size: small;
                display: block;
                font-weight: bold;
            }

            &.as-table-number {
                font-weight: 600;
                font-size: 12px;
                color: $default-text-color;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                margin: 2px 2px 5px 2px;
                padding: 5px;

                span {
                    &.as-table-room-order {
                        font-weight: 400;
                        font-size: 10px;
                        color: $default-text-color;
                        width: 90px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &.as-table-room-guest {
                        font-weight: 400;
                        font-size: 10px;
                        color: $default-text-color;
                        width: 90px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }

        }

        &.as-table-btn-taken {
            color: $default-red-color;

            span {
                &.as-table-number {
                    color: $default-red-color;
                }
            }

            svg {
                &.as-local-svg-table {

                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-text-color-light !important;

                        &.as-table-cover {
                            fill: transparentize($default-red-color, 0.5) !important;
                        }
                        
                    }
                }
            }

        }
        
        &.as-table-btn-reserved {
            color: $default-yellow-color;

            span {
                &.as-table-number {
                    color: $default-yellow-color;
                }
            }

            svg {
                &.as-local-svg-table {
    
                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-text-color-light !important;
    
                        &.as-table-cover {
                            fill: transparentize($default-yellow-color, 0.5) !important;
                        }
    
                        &.as-table-reserved-box {
                            fill: transparentize($default-red-color, 0.5) !important;
                        }
                        
                    }
    
                }
    
                text {
                    fill: #ffffff !important;
                    font-size: 3.5px !important;
                    font-weight: bold !important;
                }
                
            }
        }

        &.as-table-btn-cleaning {
            color: $default-blue-color;

            span {
                &.as-table-number {
                    background-color: $default-blue-color;
                }
            }

            svg {
                &.as-local-svg-table {
    
                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-text-color-light !important;
    
                        &.as-table-cover {
                            fill: transparentize($default-blue-color, 0.5) !important;
                        }
                        
                    }
    
                }
            }

        }

        &.as-table-btn-unusable {
            border-color: $default-text-color-light;
            color: $default-text-color-light;
            box-shadow:  5px 5px 10px transparentize($default-text-color-light, 0.9);
            span {
                &.as-table-number {
                    background-color: $default-text-color-light;
                }
            }

            svg {
                &.as-local-svg-table {
    
                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-border-color !important;
    
                        &.as-table-cover {
                            fill: transparentize($default-border-color, 0.5) !important;
                        }
                        
                    }
    
                }
            }

        }

    }

    &.as-table-option-btn {
        box-sizing: border-box;
        position: absolute;
        top: 6px;
        right: 6px;
        height: 35px;
        width: 35px;
        text-align: center;
        border-radius: 50%;
        font-size: small;
        font-weight: normal;
        background: transparentize($color: $default-border-color, $amount: 0.5);
        border: 1px solid transparent;
        color: $default-text-color;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover, &:focus {
            background: transparentize($default-border-color, 0.2);
        }
    }
    &.as-take-away-order-btn {
        box-sizing: border-box;
        height: 80px;
        width: 100%;
        display: block;
        padding: 5px;
        margin: 10px 5px;
        text-align: center;
        text-align: -webkit-center;
        cursor: pointer;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border: 2px solid transparent;
        border-radius: 5px;
        color: $default-text-color;
        background: transparent;
        outline: none !important;
        box-shadow:  0px 1px 4px transparentize(#000000, 0.8);
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
            border-color: transparent;
            border-left: 5px solid $default-theme-color;
        }

        table {
            table-layout: fixed;
            width: 100%;

            tbody {
                tr {
                    td, th {
                        span {
                            display: block;

                            &.as-guest-name{
                                padding-top: 2px;
                                color: $default-text-color-light;
                                font-style: italic;
                            }

                            &.as-order-id{
                                font-weight: 600;
                                color: $default-text-color-dark;
                            }

                            &.as-order-time {
                                color: $default-text-color-dark;
                                font-size: small;
                            }

                            &.as-success-badge {
                                padding: 5px 0;
                                border-radius: 15px;
                                font-size: small;
                                background: $default-green-color;
                                color: #ffffff;
                            }

                            &.as-warning-badge {
                                padding: 5px 0;
                                border-radius: 15px;
                                font-size: small;
                                background: $default-yellow-color;
                                color: #ffffff;
                            }

                            &.as-danger-badge {
                                padding: 5px 0;
                                border-radius: 15px;
                                font-size: small;
                                background: $default-red-color;
                                color: #ffffff;
                            }


                        }
                        &:nth-child(1) {
                            width: 40%;
                            text-align: left;
                        }
                        &:nth-child(2) {
                            width: 30%;
                            text-align: left;
                        }
                        &:nth-child(3) {
                            width: 30%;
                            text-align: center;
                        }
                    }
                }
            }

        }
    }

    &.as-update-hold-order-btn {
        position: absolute;
        right: 100px;
        bottom: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid $default-theme-color;
        color: #ffffff;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
        text-decoration: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        overflow: hidden;
        background: $default-theme-color;
        background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
        box-shadow: -2px 2px 8px transparentize(#000000, 0.8);
        display: block;
        animation: displayUpdateButton 0.3s ease-in-out;
        -webkit-animation: displayUpdateButton 0.3s ease-in-out;
        -moz-animation: displayUpdateButton 0.3s ease-in-out;
    }

    @keyframes displayUpdateButton {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }
    
    @-webkit-keyframes displayUpdateButton {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }
    
    @-moz-keyframes displayUpdateButton {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

}


//  Button for opening slider popup
button {
    &.as-open-filter-btn {
        position: fixed;
        bottom: 6.8vh;
        left: $default-sidebar-width;
        z-index: $default-sidebar-z-index;
        padding: 5px 10px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        background: transparentize($default-theme-color, 0.1);
        color: #ffffff;
        border: 2px solid transparent;
        cursor: pointer;
        box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
            cursor: pointer;
            outline: none;
            padding: 5px 15px;
            border: 2px solid transparent;
            box-shadow:  0px 4px 8px transparentize(#000000, 0.6);
            background: transparentize($default-text-color-dark, 0.1);
            color: #fff;
        }
    }
}

.sub-window {
    button {
        &.as-open-filter-btn {
            left: 0;
        }
    }
}

@media only screen and (max-width: 560px) {
    button {
        &.as-open-filter-btn {
            left: $default-sidebar-width-small;
        }
    }
}


//  Day End Button
button {
    &.day-end-btn {
        @include btnProp;
        background: #fff;
        border: 2px solid transparent;
        border-radius: 5px;
        box-shadow: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        margin-right: 10px;
        .icon-wrapper {
            --day-end-btn-icon-wrapper-size: 60px;
            height: var(--day-end-btn-icon-wrapper-size);
            width: var(--day-end-btn-icon-wrapper-size);
            border-radius: var(--day-end-btn-icon-wrapper-size);
            background-color: transparentize($default-text-color-light, 0.9);
            margin-bottom: calc(var(--day-end-btn-icon-wrapper-size) * 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
                color: $default-text-color-light;
                font-size: calc(var(--day-end-btn-icon-wrapper-size) * 0.5);
            }
        }
        span {
            &.title {
                color: $default-text-color-dark;
                font-size: 14px;
                font-weight: 500;
            }
        }

        &.start-day-end {
            .icon-wrapper {
                background-color: transparentize($default-blue-color, 0.9);
                .icon {
                    color: $default-blue-color;
                }
            }
        }
        &.cancel-day-end {
            .icon-wrapper {
                background-color: transparentize($default-red-color, 0.9);
                .icon {
                    color: $default-red-color;
                }
            }
        }
        &.open-new-date {
            .icon-wrapper {
                background-color: transparentize($default-green-color, 0.9);
                .icon {
                    color: $default-green-color;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

}


//  Mostly used in table list in action column
button {
    &.list-action-icon-btn {
        --list-action-button-size: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: transparentize($default-text-color-light, 0.9);
        color: $default-text-color-light;
        border: 1px solid transparentize($default-text-color-light, 0.9);
        height: var(--list-action-button-size);
        width: var(--list-action-button-size);
        border-radius: var(--list-action-button-size);
        margin-left: calc(var(--list-action-button-size) * 0.2);
        cursor: pointer;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        &:hover, &:focus {
            background-color: transparentize($default-text-color-light, 0.65);
            color: $default-text-color;
            &::before {
                content: attr(data-title);
                position: absolute;
                background-color: $default-text-color;
                color: #fff;
                white-space: nowrap;
                padding: calc(var(--list-action-button-size) * 0.25) calc(var(--list-action-button-size) * 0.5);
                border-radius: 5px;
                right: calc(var(--list-action-button-size) + 6px);
                pointer-events: none;
                z-index: 4;
            }
            &::after {
                content: '';
                position: absolute;
                padding: 0;
                height: 0;
                width: 0;
                border: 8px solid transparent;
                border-left-color: $default-text-color;
                right: var(--list-action-button-size);
                transform: translateX(50%);
                pointer-events: none;
                z-index: 4;
            }
        }

        &.theme {
            background-color: transparentize($default-theme-color, 0.9);
            color: $default-theme-color;
        }
        &.red {
            background-color: transparentize($default-red-color, 0.9);
            color: $default-red-color;
        }
        &.green {
            background-color: transparentize($default-green-color, 0.9);
            color: $default-green-color;
        }
        &.blue {
            background-color: transparentize($default-blue-color, 0.9);
            color: $default-blue-color;
        }
        &.yellow {
            background-color: transparentize($default-yellow-color, 0.9);
            color: $default-yellow-color;
        }
        &.purple {
            background-color: transparentize($default-purple-accent, 0.9);
            color: $default-purple-accent;
        }

        &.solid {
            background-color: $default-text-color-light;
            color: #fff;
            &.theme {
                background-color: $default-theme-color;
            }
            &.red {
                background-color: $default-red-color;
            }
            &.green {
                background-color: $default-green-color;
            }
            &.blue {
                background-color: $default-blue-color;
            }
            &.yellow {
                background-color: $default-yellow-color;
            }
            &.purple {
                background-color: $default-purple-accent;
            }
        }

    }
}

.button-group {
    &.x-small {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        flex-wrap: wrap;

        button {
            background-color: transparentize($default-theme-color, 0.5);
            border: 1px solid $default-theme-color;
            border-radius: 2px;
            padding: 3px 6px;
            color: $default-text-color-dark;
        }
    }
}