@import "variables.scss";

.as-menu-list-container {
    padding: 5px !important;
    border-radius: 5px !important;
}

.as-menu-list-item {
    padding: 10px 15px !important;
    border-radius: 5px !important;
    font-size: 14px !important;

    &.for-touch {
        padding: 15px !important;
        border-radius: 5px !important;
        font-size: 15px !important;
    }
}


//  For Topbar profile menu
.as-topbar-menu-list-container {
    padding: 5px !important;
    border-radius: 5px !important;
    .as-menu-list-item {
        padding: 10px 15px !important;
        border-radius: 5px !important;
        font-size: 14px !important;
    
        &.for-touch {
            padding: 15px !important;
            border-radius: 5px !important;
            font-size: 15px !important;
        }

        .menu-icon {
            margin-right: 10px;
        }

    }

    .as-menu-list-header-info {
        padding: 5px !important;

        .profile-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 5px 0;
            --_profile-size: 40px;

            .icon-container {
                height: var(--_profile-size);
                width: var(--_profile-size);
                display: flex;
                justify-content: center;
                align-items: flex-end;
                margin-right: calc(var(--_profile-size) * 0.3);
                border: 2px solid transparentize($default-border-color, 0.5);
                border-radius: calc(var(--_profile-size) * 2);
                background-color: transparentize($default-border-color, 0.1);
                overflow: hidden;
                
                .profile-icon {
                    color: #ffffff;
                    font-size: 2.5rem;
                    text-anchor: middle;
                }
            }

            .profile-details {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 3px;

                span {
                    &.name {
                        color: $default-text-color-dark;
                        font-weight: bold;
                        font-size: 1.25rem;
                    }
                }
            }
        }

        .support-info {
            background-color: $default-green-color;
            background: $default-green-color;
            background-image: repeating-linear-gradient(
                -45deg,
                darken($default-green-color, 4%) 0,
                darken($default-green-color, 4%) 40px,
                transparent 0,
                transparent 50%
            );
            background-size: 100px 100px;
            color: #ffffff;
            border-radius: 5px;
            padding: 12px 10px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            span {
                &.header {
                    font-size: 11px;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    margin-left: 3px;

                    &.email {
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                }
            }

            .support-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .icon-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    font-size: 18px;

                    .icon-divider {
                        width: 1px;
                        height: 12px;
                        background-color: #ffffffcc;
                    }
                }

                .phone-number-container, .email-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        &.phone-number, &.email {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 3px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &.email {
                            text-transform: lowercase;
                        }
                    }
                }

            }
        }
    }
}
