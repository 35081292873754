//  ==========================================================================
//  Created Date    :   March 30, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists Pagination styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-pagination-container {
    display: block;
    background: red;
    padding: 10px 0px;
    margin: 0;

    button {
        margin: 0 5px;
        padding: 5px 10px;
    }

    // li {
    //     list-style: none;
    //     padding: 0 5px;

    //     a {
    //         cursor: pointer !important;
    //         color: $default-text-color-light;
    //         background: transparent;
    //         padding: 2px 5px;
    //         border: 1px solid transparent;
    //         border-radius: 50%;
    //         height: 25px;
    //         width: 25px;

    //         &:hover, &:focus {
    //             background: $default-border-color;
    //             color: #fff;
    //             border-radius: 50%;
    //         }

    //         &.as-prev-link-btn, &.as-next-link-btn {
    //             text-decoration: none;
    //             user-select: none;
    //             background: transparent;
    //             color: $default-theme-color;
    //             padding: 5px 10px;

    //             &:hover, &:focus {
    //                 color: $default-text-color-dark;
    //             }
    //         }

    //         &.as-pagination-btn-active {
    //             background: $default-theme-color;
    //             border-color: $default-theme-color;
    //             color: #ffffff;
    //         }

    //         &.as-pagination-btn-disabled {
    //             background: transparentize($default-border-color, 0.2);
    //             border-color: transparentize($default-border-color, 0.2);
    //             color: transparentize(#ffffff, 0.2);
    //             cursor: not-allowed !important;
    //         }

    //     }
    // }
}
