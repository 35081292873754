@import "variables.scss";

.lead-profile-parent-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    .lead-profile-content {
        flex: 1;
    }

    .lead-profile-form-content {
        flex: 2;
    }

}

@media only screen and (max-width: 768px) {
    .lead-profile-parent-container {
        flex-direction: column;
    }
}

.lead-profile-container {
    background: transparentize($default-border-color, 0.9);
    border-radius: 10px;
    height: 100%;
    padding-bottom: 20px;

    .profile-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        padding: 25px;

        .profile-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
    
            .profile {
                width: 50px;
                height: 50px;
                background-color: transparentize($default-border-color, 0.5);
                border-radius: 50px;
                border: 2px solid transparentize($default-border-color, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
    
                span {
                    &.profile-name {
                        color: $default-text-color-light;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
    
            .profile-details {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 2px;
                flex: 1;
    
                span {
                    &.name {
                        font-size: clamp(1.25rem, 2vw, 1.5rem);
                        color: $default-text-color-dark;
                        max-width: 200px;
                        overflow-wrap: break-word;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: 500;
                    }
                    &.company {
                        color: $default-text-color-light;
                        font-size: 14px;
                    }
                }
            }
        }
    
        .rating-container {
            .rating-item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                font-size: 16px;
                font-weight: bold;

                &.hot {
                    color: $default-red-color;
                }
                &.warm {
                    color: $default-yellow-color;
                }
                &.cold {
                    color: $default-blue-color;
                }
            }
        }
    }


    .contact-details-wrapper {
        padding: 5px 25px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;

        .contact-details-item {
            background-color: transparentize($default-border-color, 0.8);
            padding: 5px 10px;
            border: 1px solid transparentize($default-border-color, 0.8);
            border-radius: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            flex: 1;

            .icon {
                color: $default-theme-color;

                &.gray {
                    color: $default-gray-color;
                }
                &.green {
                    color: $default-green-color;
                }
                &.red {
                    color: $default-red-color;
                }
                &.blue {
                    color: $default-blue-color;
                }
                &.purple {
                    color: $default-purple-accent;
                }
            }

            .value {
                color: $default-text-color-dark;
                font-size: 14px;
                white-space: nowrap;
            }

            table {
                width: 100%;
                thead, tbody {
                    tr {
                        td, th {
                            color: $default-text-color-light;
                            &:nth-child(2) {
                                color: $default-text-color-dark;
                            }
                        }
                    }
                }
                thead {
                    tr {
                        td, th {
                            padding-bottom: 5px;
                        }
                    }
                }
                tbody {
                    tr {
                        td, th {
                            padding: 5px 0;
                            border-bottom: 1px solid transparentize($default-border-color, 0.5);
                        }

                        &:last-child {
                            td, th {
                                border-bottom: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}