@import "variables.scss";

.as-tags-container {
    .ReactTags__selected {
        padding: 5px 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 5px;
    }
    .as-tags{
        padding: 2px 2px 2px 5px;
        border: 1px solid transparent;
        border-radius: 5px;
        font-weight: 500;
        font-size: 12px;
        background-color: transparentize($default-border-color, 0.65);
        color: $default-text-color-dark;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;

        button {
            background-color: transparentize($default-border-color, 0.5);
            border: 1px solid transparent;
            border-radius: 3px;
            color: $default-text-color-light;
        }
    }
}
