@import "variables.scss";

.call-log-container {
    width: 100%;
    max-height: 65vh;
    overflow: auto;

    .call-log-data-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &.active {
            background-color: transparentize($default-yellow-color, 0.92);
            border-radius: 10px;
            padding: 10px;
            margin: 5px 0;
        }

        &:last-child {
            .icon-timeline-container {
                &::after {
                    all: unset;
                }
            }
        }
    
        .icon-timeline-container {
            align-self: stretch;
            position: relative;
            overflow: hidden;

            --_timeline-icon-size: 35px;
            .icon-wrapper {
                width: var(--_timeline-icon-size);
                height: var(--_timeline-icon-size);
                border-radius: calc(var(--_timeline-icon-size) * 2);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparentize($default-border-color, 0.9);

                .icon {
                    font-size: 15px;
                    color: $default-text-color-light;
                    &.success {
                        color: $default-green-color;
                    }
                    &.failed {
                        color: $default-red-color;
                    }
                }
            }

            &::after {
                content: '';
                width: 1px;
                height: 100%;
                background-color: transparentize($default-border-color, 0.65);
                position: absolute;
                left: 50%;
                top: var(--_timeline-icon-size);
                transform: translateX(-50%);
            }

        }
    
        .call-details-container {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-direction: column;
            gap: 10px;
            flex: 1;
            padding: 0 15px 30px;
    
            .call-status-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
    
                .date-time-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 2px;
    
                    span {
                        &.date {
                            font-size: 1.25rem;
                            font-weight: 500;
                            color: $default-text-color-dark;
                        }
                        &.time {
                            font-size: 1rem;
                            color: $default-text-color-light;
                        }
                    }
                }

                .status-wrapper {
                    background-color: transparentize($default-text-color-light, 0.9);
                    border: 1px solid transparentize($default-text-color-light, 0.9);
                    border-radius: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 6px 12px;

                    span {
                        &.call-status {
                            white-space: nowrap;
                            text-transform: uppercase;
                            color: $default-text-color;
                            font-weight: 500;
                            max-width: 150px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    &.success {
                        background-color: transparentize($default-green-color, 0.9);
                        border-color: transparentize($default-green-color, 0.9);
                        span.call-status {
                            color: $default-green-color;
                        }
                    }
                    &.failed {
                        background-color: transparentize($default-red-color, 0.9);
                        border-color: transparentize($default-red-color, 0.9);
                        span.call-status {
                            color: $default-red-color;
                        }
                    }
                }
            }

            .call-details-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 5px;

                .call-details-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    .icon {
                        color: $default-gray-color;
                    }
                    span {
                        color: $default-text-color-light;
                        word-break: break-all;
                    }
                }
            }
        }
    }
}