//  ==========================================================================
//  Created Date    :   December 12, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists FO's Hotel Position styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-room-status-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 15px;

    .as-options {
        display: flex;

        button {
            display: inline-block;
            padding: 4px 8px;
            text-align: center;
            cursor: pointer;
            white-space: nowrap;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            font-size: $default-font-size;
            font-weight: 500;
            position: relative;
            overflow: hidden;
            border: 1px solid $default-border-color;
            color: $default-text-color-dark;
            margin: 0;
            border-radius: 5px;
        }
    }

    .as-interval {
        display: flex;
        justify-content: center;
        align-items: center;

        .as-interval-btn {
            display: inline-block;
            padding: 4px 8px;
            text-align: center;
            cursor: pointer;
            white-space: nowrap;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            font-size: $default-font-size;
            font-weight: 500;
            position: relative;
            overflow: hidden;
            border: 1px solid $default-border-color;
            color: $default-text-color-dark;
            margin: 0;

            &:first-of-type {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-of-type {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &:nth-of-type(2) {
                border-left: 0px;
                border-right: 0px;
            }

        }
    }
}

.as-room-status-wrapper {
    width: 100%;
    max-height: 60vh;
    overflow: auto;

    table {
        &.as-room-status-table {
            display: table;
            border-collapse: collapse;
            // border-collapse: separate;
            border-spacing: 0px;
            width: 100%;
            // min-width: 800px;
            background: #ffffff;
            text-align: center;
            font-size: $default-hotel-position-font-size;
            overflow: hidden;

            colgroup {
                col {
                    &.active-date {
                        background: lighten($default-theme-color, 45%);
                    }
                }
            }

            thead {
                tr {
                    
                    th, td {
                        padding: 5px;
                        border: 1px solid lighten($default-border-color, 16%);
                        box-shadow: 2px 1px 1px lighten($default-border-color, 16%);
                    }

                    &.tp-mn-sc {
                        th, td {

                            background: darken(#ffffff, 4%);

                            &:nth-child(1) {
                                // width: 15%;
                                background: #ffffff;
                                position: sticky;
                                left: -2px;
                                top: -2px;
                                z-index: 4;
                            }

                            &:nth-child(2) {
                                text-align: left;
                                color: $default-text-color-dark;
                                font-weight: bold;
                                position: sticky;
                                top: -2px;
                                z-index: 3;
                            }

                            &:nth-child(3) {
                                background: lighten($default-theme-color, 45%);
                            }

                            &.tp-mn-sc-act {
                                button {
                                    width: 30px;
                                    height: 30px;
                                    margin: 2px 5px;
                                    box-sizing: border-box;
                                    padding: 5px;
                                }
                                span {
                                    display: block;
                                    // padding: 10px 0 0 0;
                                }
                            }

                        }
                    }

                    &.dt-r {
                        th, td {
                            // width: 7.5%;
                            // width: 5%;
                            height: 80px;
                            position: sticky;
                            top: -2px;
                            z-index: 3;
                            background: #ffffff;

                            span {
                                &.available-rm {
                                    box-sizing: border-box;
                                    display: block;
                                    background: lighten($default-border-color, 5%);
                                    padding: 2px 5px;
                                    max-width: 50px;
                                    margin: 0 auto;
                                    border-radius: 8px;
                                    font-size: smaller;
                                    color: $default-text-color-dark;
                                    white-space: nowrap; 
                                }
                                &.month {
                                    display: block;
                                    margin: 0 auto;
                                    color: $default-text-color-dark;
                                    font-weight: bolder;
                                    font-size: 12px;
                                    padding: 5px 0 0;
                                    white-space: nowrap;
                                }
                                &.day {
                                    display: block;
                                    padding: 0 0 5px;
                                    margin: 0 auto;
                                    color: $default-text-color-dark;
                                    font-weight: bold;
                                    font-size: 10px;
                                    white-space: nowrap;
                                }
                                &.occupancy {
                                    display: block;
                                    margin: 0 auto;
                                    font-size: smaller;
                                    white-space: nowrap; 
                                }
                            }

                            &.active-date {
                                background: lighten($default-theme-color, 45%);

                                span {
                                    &.available-rm {
                                        background: $default-theme-color;
                                        color: #ffffff;
                                    }
                                    &.day {
                                        color: $default-text-color-dark;
                                    }
                                    &.occupancy {
                                        color: $default-text-color-dark;
                                    }
                                }
                            }

                        }   
                    }
                }
            }

            tbody {
                tr {
                    th, td {
                        padding: 10px;
                        border: 1px solid lighten($default-border-color, 16%);
                        overflow: visible;

                        &:first-child, &:last-child {
                            overflow: hidden;
                        }

                        &:first-child {
                            z-index: 3 !important;
                        }

                        // &:hover {
                        //     //  For Row
                        //     &::before {
                        //         background-color: transparentize($default-green-color-room-status, 0.9);
                        //         content: '';  
                        //         height: 100%;
                        //         left: -5000px;
                        //         position: absolute;  
                        //         top: 0;
                        //         width: 10000px;   
                        //         z-index: -2;
                        //     }
                        //     //  For Column
                        //     &::after {
                        //         background-color: transparentize($default-green-color-room-status, 0.9);
                        //         content: '';  
                        //         height: 10000px;    
                        //         left: 0;
                        //         position: absolute;  
                        //         top: -5000px;
                        //         width: 100%;
                        //         z-index: -1;  
                        //     }
                        // }
                        
                    }

                    &.rm-r {
                        th, td {
                            position: relative;

                            &.active-date {
                                background: transparent;
                                color: $default-text-color-dark;
                            }

                            .status {
                                position: absolute;
                                margin: 0;
                                width: 104%;
                                min-height: 22px;
                                max-height: 22px;
                                top: 50%;
                                left: 50%;
                                -moz-transform: translate(-50%, -50%);
                                -webkit-transform: translate(-50%, -50%);
                                -o-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                z-index: 2;

                                &.start {
                                    width: 60%;
                                    top: 50%;
                                    left: 50%;
                                    -moz-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -webkit-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -o-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -ms-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    margin-left: 4px;
                                    z-index: 2;
                                }

                                &.end {
                                    width: 60%;
                                    top: 50%;
                                    left: -15%;
                                    -moz-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -webkit-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -o-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -ms-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    margin-right: 6px;
                                    z-index: 1;
                                }

                                &.in-house, &.in_house {
                                    background: $default-in-house-color;
                                }

                                &.arrival {
                                    background: $default-arrival-color;
                                }

                                &.reserved {
                                    background: $default-reserved-color;
                                   
                                    &.reservation-status {
                                        
                                        &.confirmed {
                                            border-left: 4px solid $default-arrival-color;
                                        }
                                        &.provisional {
                                            border-left: 4px solid $default-light-blue-color;
                                        }
                                        &.waitlisted {
                                            border-left: 4px solid $default-yellow-color;
                                        }

                                    }

                                }

                                &.ooo {
                                    background: $default-out-of-order-color;
                                }

                                &.oos {
                                    background: $default-out-of-service-color;
                                }

                                &.mgmt-block, &.mgmt_block {
                                    background: $default-management-block-color;
                                }

                            }

                            &:nth-child(1) {
                                position: sticky !important;
                                left: -2px !important;
                                background: #ffffff;
                                z-index: 2;
                            }

                            &.hidden {
                                display: none !important;
                            }

                        }
                    }

                    &.rm-c {
                        th, td {
                            padding: 10px 5px;
                            background: darken(#ffffff, 5%);
                            color: $default-text-color-dark;
                            cursor: pointer !important;

                            &.active-date {
                                background: lighten($default-theme-color, 35%);

                                span {
                                    &.available-rm {
                                        background: $default-theme-color;
                                        color: #ffffff;
                                    }
                                }

                            }

                            &:nth-child(1) {
                                background: darken(#ffffff, 5%);
                                position: sticky !important;
                                left: -2px !important;
                                z-index: 2;
                            }

                            span {
                                &.available-rm {
                                    box-sizing: border-box;
                                    display: block;
                                    background: lighten($default-border-color, 5%);
                                    padding: 2px 5px;
                                    max-width: 50px;
                                    margin: 0 auto;
                                    border-radius: 8px;
                                    font-size: smaller;
                                    color: $default-text-color-dark;
                                    cursor: pointer;
                                    white-space: nowrap; 
                                }
                            }

                        }
                    }

                    &:hover {
                        &.rm-r {
                            th, td {
                                background-color: lighten($default-theme-color, 45%);
                            }
                        }
                        &.rm-c {
                            cursor: pointer;
                            th, td {
                                background: darken(#ffffff, 5%);
    
                                &.active-date {
                                    background: lighten($default-theme-color, 35%);
    
                                    span {
                                        &.available-rm {
                                            background: $default-theme-color;
                                        }
                                    }
    
                                }
    
                                &:nth-child(1) {
                                    background: darken(#ffffff, 5%);
                                }
    
    
                            }
                        }
                    }

                }
            }

        }
    }

}

.as-status-details-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0 0;

    .as-status-detail {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .as-reserved-status {
            color: $default-reserved-color
        }

        .as-arrival-status {
            color: $default-arrival-color;
        }

        .as-dirty-status {
            color: $default-yellow-color
        }

        .as-clean-status {
            color: $default-vacant-color
        }

        .as-departing-status {
            color: $default-departing-color
        }

        .as-in-house-status {
            color: $default-in-house-color;
        }

        .as-out-of-order-status {
            color: $default-out-of-order-color;
        }

        .as-out-of-service-status {
            color: $default-out-of-service-color;
        }

        .as-management-block-status {
            color: $default-management-block-color;
        }

        .as-todays-departure-status {
            color: $default-todays-departure-color;
        }

        .as-todays-arrival-status {
            color: $default-todays-arrival-color;
        }

        .as-stay-over-status {
            color: $default-stay-over-color;
        }

        span {
            padding-left: 5px;
            color: $default-text-color;
        }

    }
}
.divHotelPositionFullScreen {
    position: absolute !important;
    width: 90% !important;
    transition:width 0.1s linear;
  }
  
 