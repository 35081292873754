@import "variables.scss";

.gi-search-header-input-container {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    label {
        font-size: 1.25rem;
        color: $default-text-color;
        margin-right: 5px;
        text-transform: uppercase;
    }

    .input-wrapper {
        flex: 1;
    }
}


.gis-container {
    --_spacing-size: 10px;
    display: grid;
    grid-template-columns: 7fr 2fr;
    gap: var(--_spacing-size);

    .gis-content-left {
        background-color: #ffffff;
        border-radius: calc(var(--_spacing-size));
        overflow: hidden;

        .gis-content-body {
            padding: var(--_spacing-size) calc(var(--_spacing-size) * 2);
        }
    }

    .gis-content-right {
        background-color: #ffffff;
        padding: calc(var(--_spacing-size) * 1.5);
        border-radius: calc(var(--_spacing-size));
        overflow: hidden;
    }
}

.gis-profile-container {
    --_cover-picture-height: 80px;
    --_profile-picture-size: 110px;
    $default-vip-color: goldenrod;

    .cover-overlay {
        background: $default-theme-color;
        // background: linear-gradient(
        //             160deg,
        //             $default-theme-color,
        //             lighten( $default-theme-color, 10%),
        //             lighten( $default-theme-color, 10%),
        //             $default-theme-color,
        //             darken($default-theme-color, 5%),
        //             darken($default-theme-color, 10%));
        background: 
            linear-gradient(135deg,#0000 20.5%,$default-theme-color 0 29.5%,#0000 0) 0 40px,
            linear-gradient( 45deg,#0000 8%,$default-theme-color 0 17%, #0000 0 58%) 80px 0,
            linear-gradient(135deg,#0000 8%,$default-theme-color 0 17%, #0000 0 58%,$default-theme-color 0 67%,#0000 0),        
            linear-gradient( 45deg,#0000 8%,$default-theme-color 0 17%, #0000 0 58%,$default-theme-color 0 67%,#0000 0 83%,$default-theme-color 0 92%,#0000 0),
            darken($default-theme-color, 3%);
        background-size: 160px 160px;

        height: var(--_cover-picture-height);
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;

        // &.vip-guest {
        //     background: $default-vip-color;
        //     background: linear-gradient(
        //                 160deg,
        //                 $default-vip-color,
        //                 lighten( $default-vip-color, 10%),
        //                 lighten( $default-vip-color, 10%),
        //                 $default-vip-color,
        //                 darken($default-vip-color, 5%),
        //                 darken($default-vip-color, 10%));
        // }

        .room-info {
            .room-number-wrapper {
                padding: 8px 16px;
                color: #fff;
                border-radius: 5px;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                    &.room-number {
                        font-weight: bold;
                        font-size: 1.25rem;
                        text-transform: uppercase;
                        text-shadow: 0 1px 0 #00000025;
                    }
                }
            }
        }

        .dnd-tag-wrapper {
            --_dnd-border-width: 2px;
            padding: 4px 6px 4px 4px;
            position: absolute;
            top: 0;
            right: calc(var(--_spacing-size) * 2);
            background-color: darken($default-red-color, 5%);
            border: var(--_dnd-border-width) solid $default-body-background-color;
            border-top: none;
            border-radius: var(--_spacing-size);
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            clear: both;
            z-index: 0;

            .icon-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffffff;
                height: 16px;
                width: 16px;
                border-radius: 16px;
                margin-right: 4px;

                .icon {
                    font-size: 0.8rem;
                    color: $default-red-color;
                }
                svg {
                    width: 70%;
                    height: auto;
                    &.dnd-icon {
                        path {
                            fill: $default-red-color !important;
                        }
                    }
                }
            }
            span {
                color: #ffffff;
                font-size: 0.8rem;
                letter-spacing: 1px;
                font-weight: bold;
                text-shadow: 0 0 0 #ffffff;
                text-transform: uppercase;
            }

            &::before {
                position: absolute;
                top: 0;
                left: calc(-2 * var(--_spacing-size) - var(--_dnd-border-width));
                content: ' ';
                height: 0;
                width: 0;
                border: var(--_spacing-size) solid transparent;
                border-top-right-radius: var(--_spacing-size);
                box-shadow: 0 calc(-1 * var(--_spacing-size)) 0 0 $default-body-background-color;
            }
            
            &::after {
                position: absolute;
                top: 0;
                right: calc(-2 * var(--_spacing-size) - var(--_dnd-border-width));
                content: ' ';
                height: 0;
                width: 0;
                border: var(--_spacing-size) solid transparent;
                border-top-left-radius: var(--_spacing-size);
                box-shadow: 0 calc(-1 * var(--_spacing-size)) 0 0 $default-body-background-color;
            }
        }
    }

    .profile-container {
        padding: var(--_spacing-size)
                calc(var(--_spacing-size) * 2)
                var(--_spacing-size)
                calc(var(--_spacing-size) * 4);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .profile-detail-container {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            margin-top: calc(-0.5 * var(--_cover-picture-height));

            .profile-picture {
                background-color: $default-text-color-light;
                border: 5px solid #ffffff;
                height: var(--_profile-picture-size);
                width: var(--_profile-picture-size);
                border-radius: calc(var(--_profile-picture-size) * 2);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
    
                span {
                    &.text {
                        font-weight: bolder;
                        font-size: x-large;
                        color: #ffffff;
                    }
                }
            }

            .profile-detail {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                padding-left: var(--_spacing-size);

                span {
                    text-align: left;
                    text-transform: uppercase;
                    &.name {
                        color: $default-text-color-dark;
                        font-size: 1.5rem;
                        font-weight: bold;

                        .vip-icon {
                            color: $default-vip-color;
                            margin-left: 5px;
                        }
                    }
                }

                .reg-rsvn-tags {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    padding-top: 2px;

                    span {
                        &.tags {
                            background-color: transparentize($default-border-color, 0.9);
                            color: $default-text-color;
                            white-space: nowrap;
                            padding: 5px 6px;
                            border-radius: 4px;
                            font-size: 1rem;
                            font-weight: bold;
                            border: 1px solid transparentize($default-border-color, 0.9);
                        }
                    }
                }
            }
        }

        .vip-badge-container {
            // margin-top: calc(0.25 * var(--_cover-picture-height));
            width: 60px;
            height: 60px;

            svg {
                filter: drop-shadow(0px 1px 1px #00000020);
                path, rect {
                    fill: rgb(234, 180, 21);
                }
            }
        }
    }
}

.stay-information-container {
    padding: var(--_spacing-size);
    padding-top: 0;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;

    .arrival-departure-container {
        padding: var(--_spacing-size);
        --_icon-wrapper-size: 30px;

        .arrival-departure-timeline {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: calc(var(--_icon-wrapper-size) * 0.5);
            position: relative;

            .arrival-date-wrapper, .departure-date-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 0;
    
                .icon-wrapper {
                    width: var(--_icon-wrapper-size);
                    height: var(--_icon-wrapper-size);
                    border: 1px solid transparentize($default-text-color-light, 0.9);
                    border-radius: var(--_icon-wrapper-size);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparentize($default-text-color-light, 0.9);
                    margin-right: 10px;
                    .icon {
                        color: $default-text-color-light;
                        font-size: calc(var(--_icon-wrapper-size) * 0.5);
                    }
    
                    &.active {
                        background-color: transparentize($default-theme-color, 0.9);
                        border-color: transparentize($default-theme-color, 0.9);
                        .icon {
                            color: $default-theme-color;
                        }
                    }
                }
                .data-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        &.title {
                            font-size: small;
                            color: $default-text-color-light;
                        }
                        &.value {
                            font-size: 14px;
                            color: $default-text-color-dark;
                        }
                    }
                }
    
            }
    
            &:before {
                --_dotted-line-height: calc(var(--_icon-wrapper-size) * 0.85);
                content: '';
                position: absolute;
                height: var(--_dotted-line-height);
                border-left: 1px solid transparentize($default-border-color , 0.5);
                left: calc(calc(var(--_icon-wrapper-size) * 0.5) - 1px);
                top: 50%;
                transform: translateY(-50%);
                border-collapse: collapse;
                border-spacing: 0;
            }
        }

        
    }

    .occupancy-container {
        padding: var(--_spacing-size);

        .occupancy-wrapper {
            span {
                font-size: 0.9rem;
                color: $default-text-color-light;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .multiple-icon-detail-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 0;
                gap: 5px;

                .icon-detail-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 5px 8px;

                    background-color: transparentize($default-border-color, 0.9);
                    color: $default-text-color;
                    white-space: nowrap;
                    padding: 5px 6px;
                    border-radius: 4px;
                    font-size: 1rem;
                    font-weight: bold;
                    border: 1px solid transparentize($default-border-color, 0.9);

                    .icon {
                        color: $default-theme-color;
                    }

                    span {
                        &.value {
                            color: $default-text-color-dark;
                        }
                        &.title {
                            color: $default-text-color-dark;
                            letter-spacing: 1px;
                        }
                    }

                }
            }
        }
    }

    .rate-summary-container {
        padding: var(--_spacing-size);

        .total-in-house-guest {
            // background-color: $default-green-color;
            // color: #fff;
            // padding: var(--_spacing-size);
            // border-radius: 4px;
            // font-size: 1rem;
            // font-weight: bold;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: calc(var(--_spacing-size) * 1.5);
            border-radius: calc(var(--_spacing-size) * 1.5);
            background: $default-blue-color;
            background: linear-gradient(
                        135deg,
                        darken($default-blue-color, 5%),
                        $default-blue-color,
                        $default-blue-color,
                        lighten( $default-blue-color, 10%),
                        $default-blue-color,
                        $default-blue-color,
                        darken($default-blue-color, 5%));
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: #ffffff;
                font-size: 1.25rem;
                font-weight: bold;
            }
        }
        
        .rate-summary-wrapper {
            padding: calc(var(--_spacing-size) * 1.5);
            border-radius: calc(var(--_spacing-size) * 1.5);
            // background-color: $default-green-color;
            // background: $default-green-color;
            // background-image: repeating-linear-gradient(
            //     -45deg,
            //     darken($default-green-color, 4%) 0,
            //     darken($default-green-color, 4%) 40px,
            //     transparent 0,
            //     transparent 50%
            // );
            background-color: $default-green-color;
            background: 
                linear-gradient(135deg,#0000 20.5%,darken($default-green-color, 2%) 0 29.5%,#0000 0) 0 40px,
                linear-gradient( 45deg,#0000 8%,darken($default-green-color, 2%) 0 17%, #0000 0 58%) 80px 0,
                linear-gradient(135deg,#0000 8%,darken($default-green-color, 2%) 0 17%, #0000 0 58%,darken($default-green-color, 2%) 0 67%,#0000 0),        
                linear-gradient( 45deg,#0000 8%,darken($default-green-color, 2%) 0 17%, #0000 0 58%,darken($default-green-color, 2%) 0 67%,#0000 0 83%,darken($default-green-color, 2%) 0 92%,#0000 0),
                darken($default-green-color, 5%);
            background-size: 160px 160px;




            .daily-rate, .local-currency, .total-balance {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: calc(var(--_spacing-size) * 0.5) var(--_spacing-size);

                span {
                    color: #ffffff;
                    font-size: 1.3rem;
                }
            }

            .daily-rate {
                padding-bottom: calc(var(--_spacing-size) * 0.25);
                span {
                    font-weight: bold;
                }
            }

            .local-currency {
                justify-content: flex-end;
                padding-top: 0;
                span {
                    font-size: 1rem;
                }
            }

            .total-balance {
                padding: calc(var(--_spacing-size) * 0.5) var(--_spacing-size);
                span {
                    font-size: 1.1rem;
                }
            }

        }
    }
}

.arrival-departure-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    --icon-wrapper-size: 30px;
    --icon-size: 14px;

    .arrival-date-wrapper, .departure-date-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        .icon-wrapper {
            width: var(--icon-wrapper-size);
            height: var(--icon-wrapper-size);
            border: 1px solid transparentize($default-text-color, 0.9);
            border-radius: var(--icon-wrapper-size);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparentize($default-text-color, 0.9);
            margin-right: 10px;
            .icon {
                color: $default-text-color;
                font-size: var(--icon-size);
            }
        }
        .data-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            span {
                &.title {
                    font-size: small;
                    color: $default-text-color-light;
                }
                &.value {
                    font-size: 14px;
                    color: $default-text-color-dark;
                }
            }
        }
    }

    .arrival-date-wrapper {
        .icon-wrapper {
            background-color: transparentize($default-green-color, 0.9);
            border-color: transparentize($default-green-color, 0.9);
            .icon {
                color: $default-green-color;
            }
        }
    }
    .departure-date-wrapper {
        .icon-wrapper {
            background-color: transparentize($default-text-color-light, 0.9);
            border-color: transparentize($default-text-color-light, 0.9);
            .icon {
                color: $default-text-color-light;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 20%;
        border-left: 2px dotted transparentize($default-green-color, 0.9);
        border-collapse: collapse;
        border-spacing: 0;
        left: calc(var(--icon-wrapper-size) / 2);
    }
}

.in-house-body-content {
    padding: 10px;
    max-height: 50vh;
    overflow-y: auto;

    .in-house-guest-list {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        button {
            width: 100%;
            text-align: left;
            padding: 10px;
            border: 1px solid transparent;
            border-radius: 5px;
            background-color: transparentize($default-border-color, 0.92);
            text-overflow: ellipsis;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            cursor: pointer;

            &:hover, &:focus {
                border-color: transparentize($default-border-color, 0.5);
            }

            .guest-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .guest-icon-wrapper {
                    --_guest-icon-size: 35px;
                    box-sizing: border-box;
                    height: var(--_guest-icon-size);
                    width: var(--_guest-icon-size);
                    border-radius: calc(var(--_guest-icon-size) * 2);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparentize($default-red-color, 0.9);
                    margin-right: calc(var(--_guest-icon-size) * 0.25);
                    .icon {
                        color: $default-red-color;
                        // font-size: 1rem;
                    }
                }
    
                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    &.name {
                        font-size: 14px;
                        color: $default-text-color-dark;
                    }
                }
            }

            .vip-badge-container {
                // margin-top: calc(0.25 * var(--_cover-picture-height));
                width: 35px;
                height: 35px;
    
                svg {
                    filter: drop-shadow(0px 1px 1px #00000020);
                    path, rect {
                        fill: rgb(234, 180, 21);
                    }
                }
            }

        }
    }
}

.quick-links-container {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column;

    button {
        &.navigation-btn {
            width: 100%;
            text-align: left;
            display: flex;
            justify-content: left;
            align-items: center;
            padding: calc(var(--_spacing-size) * 0.75) calc(var(--_spacing-size) * 1);
            cursor: pointer;
            position: relative;
            margin: 0;
            border: 2px solid transparent;
            background-color: transparent;
            color: $default-text-color;
            font-size: 1.1rem;
            box-shadow: 0px -1px 1px -1px #00000025;
            transition: all ease-in-out 0.1s;

            &:first-child {
                box-shadow: none;
            }

            &:hover, &:focus {
                background-color: transparentize($default-border-color, 0.9);
                border-color: transparentize($default-border-color, 0.9);
                border-radius: calc(var(--_spacing-size) * 0.5);
                color: $default-text-color-dark;
                box-shadow: none;

                + button {
                    &.navigation-btn {
                        box-shadow: none;
                    }
                }
            }

            .icon-wrapper {
                --_icon-size: 30px;
                height: var(--_icon-size);
                width: var(--_icon-size);
                border-radius: calc(var(--_icon-size) * 0.35);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: var(--_spacing-size);
                background-color: transparentize($default-theme-color, 0.9);
                .icon {
                    font-size: 1rem;
                    color: $default-theme-color;
                }

                &.green {
                    background-color: transparentize($default-green-color, 0.9);
                    .icon {
                        color: $default-green-color;
                    }
                }
                &.purple {
                    background-color: transparentize($default-purple-accent, 0.9);
                    .icon {
                        color: $default-purple-accent;
                    }
                }
                &.yellow {
                    background-color: transparentize($default-yellow-color, 0.9);
                    .icon {
                        color: $default-yellow-color;
                    }
                }
                &.red {
                    background-color: transparentize($default-red-color, 0.9);
                    .icon {
                        color: $default-red-color;
                    }
                }
                &.blue {
                    background-color: transparentize($default-blue-color, 0.9);
                    .icon {
                        color: $default-blue-color;
                    }
                }
                &.light {
                    background-color: transparentize($default-text-color-light, 0.9);
                    .icon {
                        color: $default-text-color-light;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 992px) {
    .gis-container {
        grid-template-columns: 1fr;
    }

    .quick-links-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: var(--_spacing-size);

        button {
            &.navigation-btn {
                width: 100%;
                box-shadow: none;
                margin-right: calc(var(--_spacing-size) * 0.5);
                background-color: transparentize($default-border-color, 0.96);
                border-radius: calc(var(--_spacing-size) * 0.5);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .stay-information-container {
        grid-template-columns: 1fr;
        
        .occupancy-container, .rate-summary-container {
            padding-top: var(--_spacing-size);
        }
    }
}