//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Table & Mega Table styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

table {
    //  Normal Table
    &.as-table {
    width:100%;
    display:table;
    border-spacing:0;
    border-collapse:collapse;
    font-size: 15px;
    tr {
        td, th {
            padding:8px 8px;
            display:table-cell;
            text-align:left;
            vertical-align:top;
            &:first-child {
                padding-left:16px;
            }
        }
        &.rowspan-sibling {
            td, th {
                &:first-child {
                    padding-left:10px;
                }
            }
        }
    }
    }

    &.as-table-all{
        width:100%;
        display:table;
        border-spacing:0;
        border-collapse:collapse;
        font-size: 15px;
        border:1px solid #d8d8d8;
        tr {
            &:nth-child(odd) {
                background-color: #ffffff;
            }
            &:nth-child(even) {
                background-color:#f4f4f4;
            }
            td, th {
                padding:8px 8px;
                display:table-cell;
                text-align:left;
                vertical-align:top;
                &:first-child {
                    padding-left:16px;
                }
            }

            &.rowspan-sibling {
                td, th {
                    &:first-child {
                        padding-left:10px;
                    }
                }
            }
        }
    }

    &.as-table-fixed {
        table-layout: fixed !important;
    }

    &.as-table-bordered {
        tr {
            border-bottom:1px solid $default-border-color !important;
        }
    }

    &.as-table-bordered-all {
        thead, tbody, tfoot {
            tr {
                th, td {
                    border: 1px solid $default-border-color !important;
                }
            }
        }
        tr {
            th, td {
                border: 1px solid $default-border-color !important;
            }
        }
    }

    &.as-table-striped {
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: transparentize(#666666, 0.95);    
                }
            }
        }
    }

    &.as-table-hoverable {
        tbody {
            tr {
                &:hover {
                    background-color:transparentize(#666666, 0.9);
                }
                &:focus-within {
                    background-color:transparentize($default-green-color, 0.8);
                }
            }
        }
        &.without-bg {
            tbody {
                tr {
                    &:focus-within {
                        background-color: unset;
                    }
                }
            }   
        }
    }

    &.as-table-responsive{
    overflow-x: auto;
    }

    &.as-table-centered {
        tr {
            td, th {
                text-align:center;
            }
        }
    }

    &.as-table-with-input {
        table-layout: fixed;
        thead {
            tr {
                th, td {
                    padding: 8px;
                    vertical-align: middle;
                }
            }
        }
        tbody, tfoot {
            tr {
                th, td {
                    padding: 4px 8px;
                    vertical-align: middle;

                    input {
                        padding: auto;
                    }

                }
            }
        }
    }

    &.as-table-sticky-horizontal {
        thead {
            tr {
                &:first-child {
                    td, th {
                        &:first-child {
                            position: sticky;
                            left: 0 !important;
                            z-index: 5 !important;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td, th {
                    &:first-child {
                        position: sticky !important;
                        left: 0 !important;
                        z-index: 4 !important;
                        background-color: #fff !important;
                    }
                }
                &:focus-within {
                    td, th {
                        background-color: lighten($default-green-color, 30%) !important;
                    }
                }
            }
        }

        &.2 {
            thead, tbody {
                tr {
                    td, th {
                        &:nth-child(2) {
                            position: sticky;
                            left: 200px;
                        }
                    }
                }
            }
        }
    }

    tr {
        &.as-pagination-btn-row {
            box-shadow: none !important;
            th, td {
                padding-left: 0 !important;
            }
        }
    }
    
    tr {
        &.error-row {
            td, th {
                background-color: transparentize($default-red-color, 0.8);
            }
        }
    }

    &.error-row-icon {
        color: $default-red-color;
    }

    &.as-table-shadowed {
        border-collapse: unset !important;
        border-spacing: 4px !important;
        thead, tfoot {
            tr {
                box-shadow: 0px 2px 4px $default-border-color;
                th, td {
                    background: #ffffff;
                    border: none;
                    color: $default-text-color-dark;
                    padding: 8px;

                    &:first-child {
                        border-top-left-radius: 2px !important;
                        border-bottom-left-radius: 2px !important;
                    }

                    &:last-child {
                        border-top-right-radius: 2px !important;
                        border-bottom-right-radius: 2px !important;
                    }

                }
            }
        }
        tbody {
            tr {
                box-shadow: 0px 2px 4px $default-border-color;
                th, td {
                    background: #ffffff;
                    border: 1px solid transparent;
                    color: $default-text-color;
                    padding: 8px;

                    &:first-child {
                        border-top-left-radius: 2px !important;
                        border-bottom-left-radius: 2px !important;
                    }

                    &:last-child {
                        border-top-right-radius: 2px !important;
                        border-bottom-right-radius: 2px !important;
                    }

                }

                &.as-pagination-btn-row {
                    box-shadow: none !important;
                    th, td {
                        padding-left: 0 !important;
                    }
                }
            }
        }
        thead {
            tr {
                th {
                    text-transform: capitalize !important;
                }
            }
        }

        &.as-table-with-extra-row {
            border-collapse: unset !important;
            border-spacing: 4px !important;
            tbody {
                tr {
                    &:nth-child(odd) {
                        box-shadow: 0px -1px 2px $default-border-color;
                    }
                    &:nth-child(even) {
                        box-shadow: 0px 1px 2px $default-border-color;

                        th, td {
                            padding: 0px 8px 8px;
                            vertical-align: middle;

                            div {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 0;

                                label {
                                    padding-top: 6px;
                                }
                            }

                        }
                    }
                }
            }
        }

    }
}

//  ======================================================================

//  Mega Table
.as-mega-table-wrapper{
    display: block;
    width: 100%;

    .as-mega-table-header{
        background: transparent;
        padding: 0 0 5px 0;
        display: inline-block;
        width: 100%;

        .as-mega-table-header-wrapper-left {
            width: 50%;
            text-align: left;
            float: left;
            padding-left: 5px;

            button {
                box-sizing: border-box;
                height: 30px;
                width: auto;
                padding: 5px 12px;
                margin: 0 5px 0 0;
                border: none;
                border-radius: 15px;
                font-size: $default-mega-table-font-size;
                cursor: pointer;
        
                &.as-filter-solid-btn {
                    color: #ffffff;
                    border: 2px solid $default-theme-color;
                    box-shadow: transparent;
                    background: $default-theme-color;
                    background: linear-gradient(140deg, lighten($default-theme-color, 15%), $default-theme-color, darken($default-theme-color, 5%));
                    box-shadow:  0px 2px 10px transparentize($default-theme-color, 0.5);
                }
        
                &.as-filter-default-btn {
                    color: #ffffff;
                    border: 2px solid $default-gray-color;
                    background: $default-gray-color;
                    background: linear-gradient(140deg, lighten($default-gray-color, 15%), $default-gray-color, darken($default-gray-color, 5%));
                    box-shadow:  0px 2px 10px transparentize($default-gray-color, 0.5);
                }
    
            }

        }

        .as-mega-table-header-wrapper-right {
            width: 50%;
            text-align: right;
            float: right;
            padding-right: 5px;
    
            button {
    
                &.as-filter-action-btn {
                    box-sizing: border-box;
                    height: 30px;
                    width: 30px;
                    padding: 5px;
                    margin: 0 0 0 5px;
                    background: lighten($default-border-color, 6%);
                    border: none;
                    border-radius: 50%;
                    font-size: 12px;
                    cursor: pointer;
                    color: $default-text-color;
                }
        
            }
    
        }

    }

    .as-mega-table-body {
        max-height: 65vh !important;
        min-height: 65vh !important;
        min-height: auto;
        border-radius: $default-mega-table-row-radius;
        overflow: auto;

        table {
            border-radius: $default-mega-table-row-radius;

            &.as-mega-table-hoverable {
                tbody {
                    tr {
                        &:hover {
                            cursor: pointer;
                            background-color:transparentize($default-theme-color, 0.94);
                        }
                    }
                }
            }

            &.as-mega-table {
                width: 100%;
                font-size: $default-mega-table-font-size;
                background-color: transparent !important;
                border-collapse: separate !important;
                border-spacing: 0 5px !important;
                

                thead {

                    tr {
                        border-radius: $default-mega-table-row-radius;

                        th, td {
                            position: sticky;
                            top: 0px;
                            height: 30px;
                            text-align: center;
                            vertical-align: middle;
                            background-color: #ffffff;
                            color: $default-text-color-dark;
                            font-weight: bolder !important;
                            z-index: $default-mega-table-header-z-index;
                            cursor: pointer;
        
                            &:first-child {
                                border-top-left-radius: $default-mega-table-row-radius;
                                border-bottom-left-radius: 0;
                            }
        
                            &:last-child {
                                border-top-right-radius: $default-mega-table-row-radius;
                                border-bottom-right-radius: 0;
                            }
                        }

                        &:nth-child(2) {
                            th, td {
                                position: sticky;
                                height: 30px !important;
                                top: 30px;
                                background-color: lighten($default-theme-color, 48%);
                                z-index: $default-mega-table-header-z-index;

                                &:first-child {
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: $default-mega-table-row-radius;
                                }

                                &:last-child {
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: $default-mega-table-row-radius;
                                }

                                input {
                                    width: 100% !important;
                                    border-radius: $default-mega-table-row-radius !important;
                                }
                                div {
                                    border-radius: $default-mega-table-row-radius !important;
                                }    
                            }
                            td {
                                font-weight: normal !important;
                            }
                        }
                    }
                }

                tbody {

                    tr {
                        border-radius: $default-mega-table-row-radius;
                        background: #ffffff;
        
                        th, td {
                            box-sizing: border-box;
                            text-align: center;
                            height: 30px !important;
                            padding: 5px 10px;
                            vertical-align: middle;
        
                            &:first-child {
                                border-top-left-radius: $default-mega-table-row-radius;
                                border-bottom-left-radius: $default-mega-table-row-radius;
                            }
        
                            &:last-child {
                                border-top-right-radius: $default-mega-table-row-radius;
                                border-bottom-right-radius: $default-mega-table-row-radius;
                            }
        
                            .as-dropdown {
                                z-index: $default-mega-table-dropdown-z-index;
                            }

                            .as-condition-true {
                                box-sizing: border-box;
                                color: #1fe092;
                                font-weight: 600;
                                height: 10px;
                                padding: 5px 20px;
                                border: none;
                                border-radius: 10px;
                                background: transparentize(#1fe092, 0.8);
                                background: linear-gradient(140deg, lighten(transparentize(#1fe092, 0.8), 15%), transparentize(#1fe092, 0.8), darken(transparentize(#1fe092, 0.8), 5%));
                                box-shadow:  0px 2px 5px transparentize(transparentize(#1fe092, 0.8), 0.5);
                            }

                            .as-condition-false {
                                box-sizing: border-box;
                                color: #666666;
                                font-weight: 600;
                                height: 10px;
                                padding: 5px 20px;
                                border: none;
                                border-radius: 10px;
                                background: transparentize(#666666, 0.8);
                                background: linear-gradient(140deg, lighten(transparentize(#666666, 0.8), 15%), transparentize(#666666, 0.8), darken(transparentize(#666666, 0.8), 5%));
                                box-shadow:  0px 2px 5px transparentize(transparentize(#666666, 0.8), 0.5);
                            }

                        }
                    }
                }

                tfoot {
                    tr {
                        th, td {
                            box-sizing: border-box;
                            text-align: center;
                            height: 30px !important;
                            padding: 5px 10px;
                            vertical-align: middle;
                        }
                    }
                }

                .th-sort-asc {
                    &::after {
                        content: "\21be";
                        color: $default-text-color-light;
                        font-size: $default-mega-table-font-size;
                        margin-left: 5px;
                    }
                }

                .th-sort-desc {
                    &::after {
                        content: "\21c3";
                        color: $default-text-color-light;
                        font-size: $default-mega-table-font-size;
                        margin-left: 5px;
                    }
                }

                thead {
                    tr {
                        &:nth-child(2) {
                            border-spacing: 0 !important;
                        }
                    }
                }

            }

        }

        //  Start Scroller
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #d4d4d4;
            border-radius: 2px;    
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #d4d4d4;
        }
        &::-webkit-scrollbar:horizontal {
            height: 8px;
        }
        &::-webkit-scrollbar-track:horizontal {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb:horizontal {
            background: #d4d4d4;
            border-radius: 4px;    
        }
        &::-webkit-scrollbar-thumb:horizontal:hover {
            background: #d4d4d4;
        }
        //  End Scroller

    }

    .as-mega-table-footer{
        padding: 0px;
        padding-top: 15px;
        display: inline-block;
        width: 100%;

        .as-mega-table-footer-wrapper-left {
            width: 50%;
            text-align: left;
            float: left;
            padding-left: 5px;

            span {
                font-size: small;
                color: $default-text-color;

                &.as-total-rows {
                    padding-right: 5px;
                }

                &.as-page-number {
                    padding: 0 5px 0 15px;
                }

            }

            input {
                border: none !important;
                background: transparent !important;
                outline: none !important;
                box-shadow: none !important;
                border-bottom: 1px solid $default-border-color !important;
                border-radius: 0 !important;
                padding: 5px !important;
                width: 60px !important;
            }
        }

        .as-mega-table-footer-wrapper-right {
            width: 50%;
            text-align: right;
            float: right;
            padding-right: 5px;

            span {
                font-size: small;
                color: $default-text-color;

                &.as-total-rows {
                    padding-right: 15px;
                }

            }
    
            button {
    
                &.as-mega-table-footer-btn {
                    margin: 0 5px;
                    padding: 5px 12px;
                    font-size: small;
                    border: 2px solid $default-theme-color;
                    border-radius: 15px;
                    text-align: center;
                    cursor: pointer;
                    white-space: nowrap;
                    text-decoration: none;
                    user-select: none;
                    -webkit-font-smoothing: antialiased;
                    -webkit-user-drag: none;
                    -webkit-user-select: none;
                    position: relative;
                    overflow: hidden;
                    color: #ffffff;
                    background: $default-theme-color;
                    background: linear-gradient(140deg, lighten($default-theme-color, 15%), $default-theme-color, darken($default-theme-color, 5%));
                    box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);
                }
        
            }
    
        }

    }

}



//  For Dropdown Inside Table
table {
    tbody {
        tr {
            th, td {
                position: relative !important;

                .as-dropdown {
                    left: 60px !important;
                    top: 5px !important;
                    position: absolute !important;

                    .as-dropdown-content {
                        padding: 2px 5px;
                        position: relative;
                        border: 1px solid $default-border-color;
                        border-left: 2px solid $default-theme-color;
                        border-radius: 5px;
                        max-height: 35px;
                        text-align: left !important;

                        &:before {
                            content: ' ';
                            float: left;
                            height: 0px;
                            width: 0px;
                            margin-left: -22px;
                            margin-top: 6px;
                            border: 8px solid transparent;
                            border-right-color: $default-theme-color;
                        }

                        button {
                            &.as-dropdown-option-btn {
                                border: none;
                                height: 25px;
                                background-color: transparent;
                                color: $default-text-color;
                                outline: none;
                                border-radius: 5px;
                                font-size: $default-mega-table-font-size;
                                cursor: pointer;

                                &:hover, &:focus {
                                    color: $default-theme-color;
                                }
                            }
                        }
                       
                    }
                }
            }
        }
    }
}


//  For Order Details Table in POS
table {
    &.as-order-details-table {
        width: 100%;
        border: none;
        border-collapse: collapse;

        tbody {
            tr {
                th, td {
                    text-align: left;
                    color: $default-text-color;
                    text-transform: capitalize;
                    padding: 3px 0;

                    &:last-child {
                        text-align: right;
                    }
                }

                &:last-child {
                    th, td {
                        font-weight: 600;
                        color: $default-theme-color;
                        text-transform: uppercase;
                        padding-top: 15px;
                        font-size: 16px;

                        &:first-child {
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                        }

                        &:last-child {
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}

//  For sticky header table used inside card in some setup pages
.as-sticky-content-wrapper {
    
    .as-filter-table-wrapper {
        
        .as-filter-table-search {
            // position: sticky;
            // top: 0px;
            // height: 40px;
            background: #ffffff;
            // z-index: 3;
            // box-shadow: 0 0 0 100vmax #ffffff;
            // clip-path: inset(0 -100vmax);
            padding-bottom: 15px;
        }

        .as-table-container {
            margin: 0;
            padding: 0;
            overflow: auto;
            // max-height: 40vh;

            table {
                thead {
                    tr {
                        td, th {
                            background: #ffffff;
                            position: sticky;
                            // top: 40px;
                            top: 0px;
                            z-index: 3;
                        }
                        &:nth-child(2) {
                            td, th {
                                background: #ffffff;
                                position: sticky;
                                // top: 70px;
                                top: 30px;
                                z-index: 3;
                            }   
                        }
                    }
                }
            }

            &.fixed-footer {
                table {
                    tfoot {
                        tr {
                            td, th {
                                position: sticky;
                                z-index: 1;
                                background-color: #ffffff;
                                padding-top: 12px;
                                padding-bottom: 12px;
                            }
                            &:nth-last-child(1) {
                                td, th {
                                    bottom: -2px;
                                }
                            }
                            &:nth-last-child(2) {
                                td, th {
                                    bottom: 38px;
                                }
                            }
                            &:nth-last-child(3) {
                                td, th {
                                    bottom: 76px;
                                }
                            }
                            &:nth-last-child(4) {
                                td, th {
                                    bottom: 114px;
                                }
                            }
                            &:nth-last-child(5) {
                                td, th {
                                    bottom: 152px;
                                }
                            }
                            &:nth-last-child(6) {
                                td, th {
                                    bottom: 190px;
                                }
                            }
                            &:nth-last-child(7) {
                                td, th {
                                    bottom: 228px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .searchable-table-footer {
            padding: 10px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .searchable-table-footer-page-number-container {
                flex: 1;
            }

            .rows-total {
            }
        }
    }
}

//  For Blinking Row used in Search Table
table {
    tbody {
        tr {
            &.as-blink {
                animation: blinkRow .4s infinite;
                &.updated {
                    animation: blinkUpdatedRow .4s infinite;
                }
            }

            @keyframes blinkRow {
                0% {
                    background-color: transparentize($default-theme-color, 0.96);
                }
                50% {
                    background-color: transparentize($default-theme-color, 0.80);
                }
                100% {
                    background-color: transparentize($default-theme-color, 0.96);
                }
            }

            @keyframes blinkUpdatedRow {
                0% {
                    background-color: transparentize($default-yellow-color, 0.96);
                }
                50% {
                    background-color: transparentize($default-yellow-color, 0.80);
                }
                100% {
                    background-color: transparentize($default-yellow-color, 0.96);
                }
            }

        }
    }
}

//  For Billing page Table (Bill Layout Table)
table {
    &.as-table-bill-layout {
        thead {
            tr {
                th, td {
                    border-top: 1px solid $default-border-color;
                    border-bottom: 1px solid $default-border-color;
                }
                &.as-highlighted-row {
                    th, td {
                        background-color: transparentize($default-border-color, 0.96);
                    }
                }
            }
        }
        tfoot {
            tr {
                th, td {
                    border-top: 1px solid $default-border-color;
                    border-bottom: 1px solid $default-border-color;
                }
                &.as-highlighted-row {
                    th, td {
                        background-color: transparentize($default-border-color, 0.96);
                    }
                }
            }
        }
    }
}

// Seperate class for Highlighted Row
table {
    thead {
        tr {
            &.as-highlighted-row {
                th, td {
                    background-color: transparentize($default-border-color, 0.96);
                }
            }
        }
    }
    tbody {
        tr {
            &.as-highlighted-row {
                th, td {
                    background-color: transparentize($default-border-color, 0.96);
                }
            }
        }
    }
    tfoot {
        tr {
            &.as-highlighted-row {
                th, td {
                    background-color: transparentize($default-border-color, 0.96);
                }
            }
        }
    }
}

//  For table column alignment
table {
    thead, tbody {
        &.as-vt-align-top {
            tr {
                td, th {
                    vertical-align: top;
                }
            }
        }
        &.as-vt-align-middle {
            tr {
                td, th {
                    vertical-align: middle;
                }
            }
        }
        &.as-vt-align-bottom {
            tr {
                td, th {
                    vertical-align: bottom;
                }
            }
        }
    }
}


//  For Table that exceeds maximum height
.max-height-table-wrapper {
    position: relative;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;

    table {
        thead {
            tr {
                td, th {
                    background-color: #ffffff;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                }
                &:nth-child(2) {
                    td, th {
                        top: 31px;
                    }   
                }
            }
        }
        tfoot {
            tr {
                td, th {
                    position: sticky;
                    z-index: 1;
                    background-color: #ffffff;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                &:nth-last-child(1) {
                    td, th {
                        bottom: 0;
                    }
                }
                &:nth-last-child(2) {
                    td, th {
                        bottom: 38px;
                    }
                }
                &:nth-last-child(3) {
                    td, th {
                        bottom: 76px;
                    }
                }
            }
        }
    }
}


//  For Print Ready Table Display on Popup (Voucher Print)
table {
    &.voucher-details-table {
        border-collapse: separate !important;
        border-spacing: 4px !important;
        thead, tbody, tfoot {
            tr {
                td, th {
                    border: none !important;
                    &:hover {
                        background-color: transparent !important;
                    }
                }
            }
        }
        thead, tfoot {
            tr {
                td, th {
                    border-bottom: 1px solid $default-border-color !important;
                    color: $default-text-color-dark !important;
                    font-size: bold !important;
                }
            }
        }
        tbody {
            tr {
                td, th {
                    border: none !important;
                    color: $default-text-color !important;
                }
            }
        }
        tfoot {
            tr {
                &:first-child {
                    td, th {
                        border-top: 1px solid $default-border-color !important;
                    }
                }
            }
        }
    }
}


//  Apply padding
table {
    &.as-table-padding-5 {
        thead, tbody, tfoot {
            tr {
                td, th {
                    padding: 5px;
                    table {
                        thead, tbody, tfoot {
                            tr {
                                td, th {
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}