//  ==========================================================================
//  Created Date    :   April 20, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists styles used in POS Touch Order Page.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";
@import "mixins/mixins.scss";

.as-outlet-name-pos-order {
    font-size: 14px;
}

.touch-screen {
    padding: 15px;
}

.container-header {
    font-size: 16px;
    color: $default-text-color-dark;
}

.left-container {
    box-sizing: border-box;
    background: #fff;
    border-radius: 25px;
    padding: 15px !important;
    overflow: hidden;
    min-height: 84vh;
    max-height: 84vh;

    .container-header {
        padding: 15px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            &.title {
                &::after {
                    content: '';
                    bottom: 10px;
                    left: 15px;
                    position: absolute;
                    height: 2px;
                    width: 40px;
                    background: $default-theme-color;
                    background: linear-gradient(-140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                }
            }
        }

        .action-wrapper {
            float: right;

            .as-option-btn {
                outline: none;
                border: 2px solid $default-text-color-light;
                border-radius: 50%;
                text-align: center;
                cursor: pointer;
                height: 25px;
                width: 25px;
                line-height: 0;
                padding: 0;
                color: #ffffff;
                background: $default-text-color-light;
                background: linear-gradient(140deg, lighten($default-text-color-light, 5%), $default-text-color-light, darken($default-text-color-light, 5%));
                box-shadow:  0px 2px 4px transparentize($default-text-color-light, 0.5);
                transition: all 0.2s ease-in-out;
                -webkit-animation-name: showOptionForTouch;
                -webkit-animation-duration: 0.4s;
                animation-name: showOptionForTouch;
                animation-duration: 0.4s;
                
                &:hover, &:focus, &:active {
                    border-color: $default-text-color-light;
                    background: darken($color: $default-text-color-light, $amount: 5%);
                    background: linear-gradient(140deg, lighten($default-text-color-light, 8%), darken($default-text-color-light, 5%), darken($default-text-color-light, 15%));
                }

                &.green {
                    border: 2px solid $default-green-color;
                    background: $default-green-color;
                    background: linear-gradient(140deg, lighten($default-green-color, 5%), $default-green-color, darken($default-green-color, 5%));
                    box-shadow:  0px 2px 4px transparentize($default-green-color, 0.5);

                    &:hover, &:focus, &:active {
                        border-color: $default-green-color;
                        background: darken($color: $default-green-color, $amount: 5%);
                        background: linear-gradient(140deg, lighten($default-green-color, 8%), darken($default-green-color, 5%), darken($default-green-color, 15%));
                    }

                }

                @keyframes showOptionForTouch {
                    0%{
                        transform: translateX(50px) scale(0.5);
                        opacity: 0;
                    }
                    100%{
                        transform: translateX(0) scale(1);
                        opacity: 1;
                    }
                }

                @-webkit-keyframes showOptionForTouch {
                    0%{
                        transform: translateX(50px) scale(0.5);
                        opacity: 0;
                    }
                    100%{
                        transform: translateX(0) scale(1);
                        opacity: 1;
                    }
                }

            }

            .as-dropdown {

                .as-dropdown-content {
                    box-sizing: border-box;
                    border: 1px solid $default-border-color;
                    border-top: 2px solid $default-theme-color;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: $default-actionbar-dropdown-z-index;
                    top: 15px;
                    border-radius: 5px;
                    padding: 10px;
                    -webkit-animation-name: showDDTS;
                    -webkit-animation-duration: .2s;
                    animation-name: showDDTS;
                    animation-duration: .2s;
    
                    &::before {
                        content: '';
                        float: right;
                        margin-top: -30px;
                        height: 0;
                        width: 0;
                        margin-right: -4px;
                        border: 10px solid transparent;
                        border-bottom-color: $default-theme-color;
                    }
    
                    button {
                        border: 2px solid transparent;
                        background: transparent;
                        color: $default-text-color;
                        border-radius: 5px;
                        text-align: left;
                        padding: 10px;
                        margin: 2px;
                        display: block;
                        width: 100%;
                        cursor: pointer;
                        outline: none;
    
                        &:hover, &:focus {
                            color: $default-text-color-dark;
                            background-color: $default-body-background-color;
                        }
                    }
    
                    @keyframes showDDTS {
                        0%{
                            transform: translateY(50px);
                            opacity: 0;
                        }
                        100%{
                            transform: translateY(0px);
                            opacity: 1;
                        }
                    }
    
                    @-webkit-keyframes showDDTS {
                        0%{
                            transform: translateY(50px);
                            opacity: 0;
                        }
                        100%{
                            transform: translateY(0px);
                            opacity: 1;
                        }
                    }

                }
    
            }

        }
        
    }

    .container-body {
        overflow-x: hidden;
        padding: 0;

        // .as-order-content {
        //     &.touch-order {
        //         // min-height: 50vh;
        //         // max-height: 50vh;
        //         width: 100%;
        //         padding: 0;
        //         margin: 0;
        
        //         .as-order-content-body {
        //             overflow-x: hidden;
        //             padding: 5px 2px;

        //             &::-webkit-scrollbar {
        //                 display: none;
        //             }
        
        //             table {
        //                 &.as-order-table {
        //                     width: 100% !important;
        //                     font-size: $default-order-table-font-size-for-touch-screen;
        //                     background-color: transparent !important;
        //                     border-collapse: separate !important;
        //                     border-spacing: 0 5px !important;
        //                     table-layout: fixed;
        
        //                     thead {
        
        //                         tr {
        //                             border-radius: $default-order-table-row-radius;
        //                             box-shadow: 0px 1px 2px $default-border-color;
        
        //                             th, td {
        //                                 position: sticky;
        //                                 top: -5px;
        //                                 height: 30px;
        //                                 padding: 10px;
        //                                 text-align: right;
        //                                 vertical-align: middle;
        //                                 background-color: $default-body-background-color;
        //                                 color: $default-text-color-dark;
        //                                 font-weight: bolder !important;
        //                                 z-index: $default-order-table-header-z-index;
        //                                 cursor: pointer;
        
        //                                 &:first-child {
        //                                     padding: 5px;
        //                                     border-top-left-radius: $default-order-table-row-radius;
        //                                     border-bottom-left-radius: 0;
        //                                     text-align: left;
        //                                 }
        
        //                                 &:nth-child(2) {
        //                                     text-align: left;
        //                                 }
        
        //                                 &:nth-child(3) {
        //                                     text-align: center;
        //                                 }
        
        //                                 &:last-child {
        //                                     border-top-right-radius: $default-order-table-row-radius;
        //                                     border-bottom-right-radius: 0;
        //                                 }
        
        //                                 //  Checkbox used inside th/td
        //                                 input {
        //                                     &[type=checkbox] {
        //                                         height: 15px !important;
        //                                         width: 15px !important;
        //                                         padding: 0 !important;
        
        //                                         &:not(.switch) {
        //                                             width: 15px !important;
        //                                             &::after {
        //                                                 width: 3px;
        //                                                 height: 5px;
        //                                                 left: 4px;
        //                                                 top: 2px;
        //                                             }
        //                                         }
        //                                     }
        //                                 }
        
        //                             }
        //                         }
        //                     }
        
        //                     tbody {
        
        //                         tr {
        //                             border-radius: $default-order-table-row-radius;
        //                             background: #ffffff;
        //                             box-shadow: 0px 1px 4px $default-border-color;
        
        //                             &.as-item-group-row {
        //                                 background: transparentize($default-theme-color, 0.84);
        //                                 color: $default-text-color-dark;
        //                                 border-bottom-left-radius: 0;
        //                                 border-bottom-right-radius: 0;
        //                                 text-align: left;
        
        //                                 th, td {
                                            
        //                                     &:first-child {
        //                                         border-bottom-left-radius: 0px !important;
        //                                     }
                                            
        //                                     &:last-child {
        //                                         border-bottom-right-radius: 0px !important;
        //                                         text-align: left;
        //                                     }
        
        //                                 }
        
        //                                 &:hover {
        //                                     background-color: transparentize($default-theme-color,0.84);
        //                                     cursor: pointer;
        //                                 }
        //                             }
        
        //                             th, td {
        //                                 box-sizing: border-box;
        //                                 text-align: right;
        //                                 padding: 10px;
        //                                 vertical-align: middle;
        //                                 border-bottom: 1px solid transparentize($default-border-color, 0.5);
        
        //                                 &:first-child {
        //                                     border-top-left-radius: $default-order-table-row-radius;
        //                                     border-bottom-left-radius: $default-order-table-row-radius;
        //                                     text-align: left;
        //                                     width: 10%;
        //                                 }
        
        //                                 &:nth-child(2) {
        //                                     text-align: left;
        //                                     width: 40%;
        //                                 }
        
        //                                 &:nth-child(3) {
        //                                     text-align: center;
        //                                     width: 35%;
        //                                 }
        
        //                                 &:last-child {
        //                                     text-align: right;
        //                                     border-top-right-radius: $default-order-table-row-radius;
        //                                     border-bottom-right-radius: $default-order-table-row-radius;
        //                                     width: 15%;
        //                                     white-space: normal;
        //                                 }
        
        //                                 //  Checkbox used inside th/td
        //                                 input {
        //                                     &[type=checkbox] {
        //                                         height: 15px !important;
        //                                         width: 15px !important;
        //                                         padding: 0 !important;
        
        //                                         &:not(.switch) {
        //                                             width: 15px !important;
        //                                             &::after {
        //                                                 width: 3px;
        //                                                 height: 5px;
        //                                                 left: 4px;
        //                                                 top: 2px;
        //                                             }
        //                                         }
        //                                     }
        //                                 }

        //                                 span {
        //                                     &.as-kot-number {
        //                                         font-size: 11px;
        //                                         color: $default-text-color-light;
        //                                         display: block;
        //                                         padding-bottom: 2px;
        //                                     }
        //                                     &.as-item-name {
        //                                         font-size: 12px;
        //                                         color: $default-text-color-dark;
        //                                         display: block;
        //                                         padding-bottom: 2px;
        //                                     }
        //                                     &.rate {
        //                                         font-size: 11px;
        //                                         color: $default-text-color-light;
        //                                         display: block;
        //                                         width: 100%;
        //                                     }
        //                                     &.as-kitchen-note-text {
        //                                         font-size: 11px;
        //                                         color: $default-text-color;
        //                                         padding: 5px 0;
        //                                     }
        //                                 }
        
        //                                 .as-quantity-div {
        
        //                                     button {
        //                                         &.as-change-quantity-btn {
        //                                             box-sizing: border-box;
        //                                             width: 25px;
        //                                             height: 25px;
        //                                             color: $default-green-color;
        //                                             background: transparent;
        //                                             border: none;
        //                                             border-radius: 50%;
        //                                             font-size: 14px;
        //                                             line-height: 0;
        //                                             text-align: center;
        //                                             padding: 0;
        //                                             margin: 0;
        //                                             cursor: pointer;
        //                                             box-shadow: none;
        //                                             display: inline-flex;
        //                                             justify-content: center;
        //                                             align-items: center;
        
        //                                             &.as-order-decrease-btn {
        //                                                 background: #ffffff;
        //                                                 color: $default-text-color-light;
        //                                                 box-shadow: none;
        //                                             }
        
        //                                         }
        //                                     }
        
        //                                     input {
        //                                         &[type=number] {
        //                                             max-width: 50px;
        //                                             height: unset;
        //                                             padding: 0;
        //                                             border: none;
        //                                             border-radius: 2px;
        //                                             background: transparent;
        //                                             color: $default-text-color;
        //                                             border-radius: 2px;
        //                                             outline: none;
        //                                             text-align: center;
        //                                             -webkit-appearance: textfield !important;
        //                                             -moz-appearance: textfield !important;
        //                                             appearance: textfield !important;
        
        //                                             &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        //                                                 -webkit-appearance: none !important;
        //                                             }
        
        //                                             &:hover, &:focus {
        //                                                 background: #ffffff;
        //                                                 color: $default-theme-color;
        //                                             }
        //                                         }
        //                                     }
        //                                 }
        
        //                             }
        
        //                             &:hover {
        //                                 background-color: transparentize($default-theme-color,0.98);
        //                                 cursor: pointer;
        //                             }
        //                         }
        //                     }
        
        //                     &.as-ordered-items-table {
        //                         thead, tbody {
        //                             tr {
        //                                 th, td {
        //                                     &:first-child {
        //                                         text-align: center !important;
        //                                     }
        //                                     &:nth-child(2) {
        //                                         text-align: left !important;
        //                                     }
        //                                     &:nth-child(3) {
        //                                         text-align: left !important;
        //                                     }
        //                                     &:nth-child(4) {
        //                                         text-align: center !important;
        //                                     }
        //                                     &:nth-child(5) {
        //                                         text-align: right !important;
        //                                     }
        //                                     &:nth-child(6) {
        //                                         text-align: right !important;
        //                                     }
        //                                 }
        //                             }
        //                         }
        //                     }
        
        //                 }
        //             }
        
        //         }

        //         &::-webkit-scrollbar {
        //             display: none;
        //         }
        
        //     }
        
        // }

        &.no-footer {
            min-height: 73vh;
            max-height: 73vh;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .container-footer {
        overflow: hidden;
        // min-height: 30vh;
        max-height: 30vh;
        padding: 5px 0 5px 0;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;

        .as-billing-wrapper {
            width: 100%;
            background-color: transparent;
            padding: 15px;
            border-radius: 5px;
            background: $default-body-background-color;

            .total-wrapper {
                display: grid;
                grid-gap: 0 100px;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                padding-bottom: 10px;
                
                &::-webkit-scrollbar {
                    display: none;
                }


                .total-text-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 3px 0;
    
                    span {
                        &.total-label {
                            color: darken($default-text-color-light, 2%);
                            font-size: 12px;
                            text-transform: uppercase;
                        }
                        &.total-value {
                            color: darken($default-text-color-light, 2%);
                            font-size: 12px;
                        }
                    }
                }
            }

            .grand-total-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid $default-border-color;
                padding-top: 10px;
                position: relative;

                span {
                    &.grand-total-label {
                        color: darken($default-text-color-light, 2%);
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                    &.grand-total-value {
                        color: $default-text-color-dark;
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
            }
        }

    }

}

.right-container {
    padding-left: 20px !important;
}


.as-order-process-content-touch-order {
    overflow: hidden;
    height: 86vh;
    padding-left: $default-sidebar-width;

    .as-order-process-content-body {
        // height: 75vh;
        // overflow: auto;
        padding: 0px;
        margin: 0;

        .as-take-away-order-btn-wrapper {
            display: block;
            position: relative;
            width: 100%;

            .as-take-away-action-btn {
                &:hover, &:focus {
                    border-color: transparent !important;
                }
            }

            .as-take-away-order-option-btn {
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                height: 55px;
                width: 55px;
                text-align: center;
                border-radius: 50%;
                font-size: small;
                font-weight: normal;
                background: transparentize($color: $default-border-color, $amount: 0.5);
                border: 1px solid transparent;
                color: $default-text-color;
                box-shadow: none;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover, &:focus {
                    background: transparentize($color: $default-border-color, $amount: 0.2);
                    box-shadow: 0px 1px 2px transparentize(#000000, 0.6);
                }

            }

            .as-dropdown {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 80px;

                .as-dropdown-content {
                    padding: 5px;
                    min-width: unset !important;
                    min-width: 140px !important;
                    max-width: 140px !important;
                    border-radius: 5px;
                    border-right: 2px solid $default-theme-color;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        height: 0px;
                        width: 0px;
                        padding: 0;
                        float: right;
                        right: -12px;
                        top: 50%;
                        transform: translateY(-50%);
                        border: 5px solid transparent;
                        border-left-color: $default-theme-color;
                    }

                    input {
                        &[type=button] {
                            display: block;
                            width: 100%;
                            cursor: pointer;
                            margin: 5px 0;
                            padding: 5px;
                            border: none;
                            border-radius: 2px;
                            background: transparent;
                            color: $default-text-color;
                            outline: none;
                            text-align: left;

                            &:hover, &:focus {
                                color: $default-text-color-dark;
                                background: $default-body-background-color;
                            }

                            &.for-touch {
                                padding: 10px 8px;

                                &:first-child {
                                    margin-top: 0;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

            }

            .as-take-away-order-btn {
                box-sizing: border-box;
                height: auto;
                min-height: 60px;
                width: 100%;
                display: block;
                padding: 10px;
                margin: 10px 5px;
                text-align: center;
                text-align: -webkit-center;
                cursor: pointer;
                text-decoration: none;
                user-select: none;
                -webkit-font-smoothing: antialiased;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                font-weight: 500;
                position: relative;
                overflow: hidden;
                border: 2px solid transparent;
                border-left: 10px solid transparentize($default-red-color, 0.5);
                border-radius: 10px;
                color: $default-text-color;
                background: #fff;
                box-shadow: 0px 2px 4px transparentize($default-border-color, 0.6);
                transition: all 0.2s ease-in-out;

                &:hover, &:focus {
                    box-shadow: 0px 3px 6px $default-border-color;
                    // transform: scale(1.02);
                }

                &:focus {
                    border-color: transparentize($default-red-color, 0.5);
                }

                table {
                    table-layout: fixed;
                    width: 100%;

                    tbody {
                        tr {
                            td, th {
                                span {
                                    display: block;

                                    &.as-guest-name{
                                        padding-top: 2px;
                                        color: $default-text-color-light;
                                        font-style: italic;
                                    }

                                    &.as-order-id{
                                        font-weight: 600;
                                        color: $default-text-color-dark;
                                    }

                                    &.as-order-time {
                                        color: $default-text-color-dark;
                                        font-size: small;
                                    }

                                    &.as-success-badge {
                                        padding: 5px 0;
                                        border-radius: 15px;
                                        font-size: small;
                                        background: $default-green-color;
                                        color: #ffffff;
                                    }

                                    &.as-warning-badge {
                                        padding: 5px 0;
                                        border-radius: 15px;
                                        font-size: small;
                                        background: $default-yellow-color;
                                        color: #ffffff;
                                    }

                                    &.as-danger-badge {
                                        padding: 5px 0;
                                        border-radius: 15px;
                                        font-size: small;
                                        background: $default-red-color;
                                        color: #ffffff;
                                    }


                                }
                                &:nth-child(1) {
                                    width: 30%;
                                    text-align: left;
                                }
                                &:nth-child(2) {
                                    width: 30%;
                                    text-align: left;
                                }
                                &:nth-child(3) {
                                    width: 40%;
                                    text-align: right;
                                }
                            }
                        }
                    }

                }
            }

        }

        .as-table-div {
            ul {
                li {
                    &.as-table-list {
                        list-style: none !important;
                        overflow: visible;
                        margin: 0;
                        padding: 0;

                        .as-table-area {
                            display: block;
                            padding: 20px 5px 5px;
                            position: sticky;
                            left: 0;

                            span {
                                color: $default-text-color;
                                font-weight: bold;
                            }

                        }

                        .as-table-btn-wrapper {
                            display: inline-block;
                            position: relative;

                            //  POS Order Page Table Buttons Inside Tabs
                            .as-table-btn {
                                height: 200px;
                                width: 100%;
                                padding: 0px;
                                text-align: center;
                                text-align: -webkit-center;
                                cursor: pointer;
                                text-decoration: none;
                                user-select: none;
                                -webkit-font-smoothing: antialiased;
                                -webkit-user-drag: none;
                                -webkit-user-select: none;
                                font-weight: 500;
                                position: relative;
                                overflow: hidden;
                                border: 1px solid transparent;
                                border-radius: 15px;
                                color: $default-border-color;
                                background: #fff;
                                box-shadow: 5px 5px 10px transparentize($default-border-color, 0.6);
                                transition: all 0.2s ease-in-out;

                                &:first-child {
                                    margin-left: 5px;
                                }

                                &:last-child {
                                    margin-right: 5px;
                                }

                                .as-table-icon {
                                    font-size: 25px;
                                    display: block;
                                    margin-bottom: 10px;
                                    color: $default-border-color;
                                }

                                svg {
                                    &.as-local-svg-table {
                                        height: 30px;

                                        path, circle, rect, line, polygon, ellipse {
                                            fill: $default-text-color-light !important;
                                            &.as-table-cover {
                                                fill: transparentize($default-green-color, 0.5) !important;
                                            }
                                            
                                        }

                                    }

                                }

                                span {
                                    
                                    &.as-table-total-cover {
                                        position: absolute;
                                        top: 15px;
                                        left: 10px;
                                        font-size: x-small;
                                        display: block;
                                        color: $default-text-color;
                                    }

                                    &.as-table-time-taken {
                                        position: absolute;
                                        top: 25px;
                                        left: 10px;
                                        font-size: x-small;
                                        display: block;
                                        color: $default-text-color;
                                    }

                                    &.as-merged-icon {
                                        position: absolute;
                                        bottom: 35px;
                                        right: 8px;
                                        font-size: small;
                                        display: block;
                                        font-weight: bold;
                                    }

                                    &.as-table-number {
                                        font-weight: 600;
                                        font-size: 12px;
                                        color: $default-text-color;
                                        position: absolute;
                                        bottom: 0;
                                        right: 0;
                                        left: 0;
                                        margin: 2px 2px 5px 2px;
                                        padding: 5px;
                                        text-align: center;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                }

                                &.as-table-btn-taken {
                                    color: $default-red-color;

                                    span {
                                        &.as-table-number {
                                            color: $default-red-color;
                                        }
                                    }

                                    svg {
                                        &.as-local-svg-table {

                                            path, circle, rect, line, polygon, ellipse {
                                                fill: $default-text-color-light !important;

                                                &.as-table-cover {
                                                    fill: transparentize($default-red-color, 0.5) !important;
                                                }
                                                
                                            }
                                        }
                                    }

                                }
                                
                                &.as-table-btn-reserved {
                                    color: $default-yellow-color;

                                    span {
                                        &.as-table-number {
                                            color: $default-yellow-color;
                                        }
                                    }

                                    svg {
                                        &.as-local-svg-table {
                            
                                            path, circle, rect, line, polygon, ellipse {
                                                fill: $default-text-color-light !important;
                            
                                                &.as-table-cover {
                                                    fill: transparentize($default-yellow-color, 0.5) !important;
                                                }
                            
                                                &.as-table-reserved-box {
                                                    fill: transparentize($default-red-color, 0.5) !important;
                                                }
                                                
                                            }
                            
                                        }
                            
                                        text {
                                            fill: #ffffff !important;
                                            font-size: 3.5px !important;
                                            font-weight: bold !important;
                                        }
                                        
                                    }
                                }

                                &.as-table-btn-cleaning {
                                    color: $default-blue-color;

                                    span {
                                        &.as-table-number {
                                            background-color: $default-blue-color;
                                        }
                                    }

                                    svg {
                                        &.as-local-svg-table {
                            
                                            path, circle, rect, line, polygon, ellipse {
                                                fill: $default-text-color-light !important;
                            
                                                &.as-table-cover {
                                                    fill: transparentize($default-blue-color, 0.5) !important;
                                                }
                                                
                                            }
                            
                                        }
                                    }

                                }

                                &.as-table-btn-unusable {
                                    border-color: $default-text-color-light;
                                    color: $default-text-color-light;
                                    box-shadow:  5px 5px 10px transparentize($default-text-color-light, 0.9);
                                    span {
                                        &.as-table-number {
                                            background-color: $default-text-color-light;
                                        }
                                    }

                                    svg {
                                        &.as-local-svg-table {
                            
                                            path, circle, rect, line, polygon, ellipse {
                                                fill: $default-border-color !important;
                            
                                                &.as-table-cover {
                                                    fill: transparentize($default-border-color, 0.5) !important;
                                                }
                                                
                                            }
                            
                                        }
                                    }

                                }

                            }

                            .as-table-option-btn {
                                box-sizing: border-box;
                                position: absolute;
                                top: 5px;
                                right: 0px;
                                height: 55px;
                                width: 55px;
                                text-align: center;
                                border-radius: 50%;
                                font-size: 16px;
                                font-weight: 500;
                                background: transparentize($color: $default-border-color, $amount: 0.5);
                                border: 1px solid transparent;
                                color: $default-text-color-light;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &:hover, &:focus {
                                    background: transparentize($default-border-color, 0.2);
                                }
                            }

                            &:first-child {
                                .as-table-btn {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                    &:first-child {
                        &.as-table-list {
                            .as-table-area {
                                padding-top: 10px;
                            }
                        }
                    }

                }
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: unset !important;
            padding: 10px !important;
            .title-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                .icon-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    --icon-wrapper-size: 30px;
                    height: var(--icon-wrapper-size);
                    width: var(--icon-wrapper-size);
                    border-radius: var(--icon-wrapper-size);
                    background-color: transparentize($default-theme-color, 0.9);
                    overflow: hidden;
                    margin-right: calc(var(--icon-wrapper-size) * 0.5);
                    .icon {
                        font-size: calc(var(--icon-wrapper-size) * 0.5);
                        color: $default-theme-color;
                    }
                    svg {
                        width: calc(var(--icon-wrapper-size) * 0.75) !important;
                        height: auto !important;
                        &.as-local-svg {
                            path, circle, rect, line, polygon, ellipse {
                                fill: $default-theme-color !important;
                            }
                        }
                    }
                    &.red {
                        background-color: transparentize($default-red-color, 0.9);
                        .icon {
                            color: $default-red-color;
                        }
                        svg {
                            &.as-local-svg {
                                path, circle, rect, line, polygon, ellipse {
                                    fill: $default-red-color !important;
                                }
                            }
                        }
                    }
                    &.green {
                        background-color: transparentize($default-green-color, 0.9);
                        .icon {
                            color: $default-green-color;
                        }
                        svg {
                            &.as-local-svg {
                                path, circle, rect, line, polygon, ellipse {
                                    fill: $default-green-color !important;
                                }
                            }
                        }
                    }
                    &.yellow {
                        background-color: transparentize($default-yellow-color, 0.9);
                        .icon {
                            color: $default-yellow-color;
                        }
                        svg {
                            &.as-local-svg {
                                path, circle, rect, line, polygon, ellipse {
                                    fill: $default-yellow-color !important;
                                }
                            }
                        }
                    }
                }
                .title-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    button {
                        &.button-title {
                            padding: 0;
                            margin: 0;
                            border: 0;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            line-height: 0;
                            letter-spacing: 0;
                            span {
                                &.title {
                                    font-size: 16px;
                                    color: $default-text-color-dark;
                                    line-height: 0;
                                    letter-spacing: 0;
                                }
                            }
                            .button-icon {
                                padding-left: 10px;
                                font-size: 16px;
                                line-height: 0;
                                letter-spacing: 0;
                            }
                        }
                    }
                    span {
                        &.title {
                            font-size: 16px;
                            color: $default-text-color-dark;
                        }
                        &.description {
                            font-size: 12px;
                            color: $default-text-color-light;
                        }
                    }
                }
            }
            .action-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .input-wrapper {
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    input {
                        width: 100%;
                        border: none;
                        outline: none;
                        color: $default-text-color;
                        background: url(../../Icons/search_icon.png) no-repeat;
                        transition: all 0.2s ease-in-out;
                        &.search-data {
                            padding: 5px 5px 5px 30px;
                            background-position: 10px;
                            background-size: 10px;
                            background-color: #fff;
                            border-radius: 15px;
                            border: none;
                            box-shadow: none !important;
                            margin: 0;
                        }
                        &:hover, &:focus {
                            border-color: $default-theme-color;
                        }
                        &::placeholder {
                            color: $default-border-color;
                        }
                        &:-moz-placeholder {
                            color: $default-border-color;
                        }
                    }
                }
            }
        }

        .sub-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px 5px !important;
        }

        .body {
            max-height: 76vh;
            white-space: nowrap !important;
            overflow: auto !important;
            &::-webkit-scrollbar {
                display: none;
            }

            &.table-list {
                max-height: 54vh;
                overflow-x: auto;
                overflow-y: visible;
                padding: 0;
                margin: 0;
            }

            .as-table-wrapper, .as-attendant-wrapper {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0 10px;
                
                &:nth-child(1) {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
                

                button {
                    box-sizing: border-box;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: none;
                    user-select: none;
                    -webkit-font-smoothing: antialiased;
                    -webkit-user-drag: none;
                    -webkit-user-select: none;
                    font-size: $default-font-size;
                    letter-spacing: 0.25px;
                    font-weight: 500;
                    overflow: hidden;
    
                    &.as-table, &.as-attendant {
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        border-color: transparent;
                        background: #fff;
                        color: $default-text-color;
                        box-shadow: 1px 2px 5px transparentize($default-border-color, 0.5);
    
                        svg {
                            &.as-local-svg-table {
                                height: 20px !important;
                                path {
                                    fill: $default-text-color-light !important;
                                }
                            }
                        }

                        .as-user-icon {
                            color: $default-text-color-light;
                            font-size: 20px;
                        }

                        &.as-selected-table, &.as-selected-attendant {
                            background: $default-theme-color;
                            background: linear-gradient(-140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                            box-shadow: 1px 2px 5px transparentize($default-theme-color, 0.5);

                            svg {
                                &.as-local-svg-table {
                                    path {
                                        fill: #fff !important;
                                    }
                                }
                            }

                            .as-user-icon {
                                color: #fff;
                            }

                            ~ {
                                span {
                                    &.as-table-number, &.as-attendant-name {
                                        color: $default-theme-color;
                                    }
                                }
                            }
                        }
    
                    }
                }
    
                span {
                    &.as-table-number, &.as-attendant-name {
                        color: $default-text-color;
                        padding-top: 5px;
                    }
                }

            }

            &.as-attendant {
                padding-top: 0;
                padding-right: 0;
                padding-left: 0;
            }

            &.menu-content-body {
                .menu-list-container {
                    height: 72vh;
                    display: grid;
                    grid-template-columns: 1fr;
    
                    &:has(.menu-category-container) {
                        grid-template-columns: minmax(150px, 20%) 1fr;
                    }
    
                    .menu-category-container, .menu-items-container {
                        .menu-header {
                            span {
                                &.title {
                                    font-size: 14px;
                                    color: $default-text-color-dark;
                                    font-weight: 500;
                                }
                            }
                        }
                        .menu-body {
                            max-height: 70vh;
                            overflow: auto;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            .item-button-container {
                                display: grid;
                                grid-gap: 5px;
                                grid-template-columns: repeat(auto-fit, minmax(115px, 115px));
                            }
                            button {
                                &.as-menu-group, &.as-menu-item {
                                    @include btnProp;
                                    width: 100%;
                                    margin: 0;
                                    margin-bottom: 5px;
                                    border: 2px solid transparent;
                                    letter-spacing: 0;
                                    border-radius: 5px;
                                    box-shadow: none;
                                    background-color: #fff;
                                    display: flex;
                                    align-items: center;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
    
                                    .icon-wrapper {
                                        --menu-btn-icon-wrapper-size: 30px;
                                        height: var(--menu-btn-icon-wrapper-size);
                                        width: var(--menu-btn-icon-wrapper-size);
                                        border-radius: var(--menu-btn-icon-wrapper-size);
                                        background-color: transparentize($default-green-color, 0.9);
                                        margin-right: calc(var(--menu-btn-icon-wrapper-size) * 0.5);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        .icon {
                                            color: $default-green-color;
                                            font-size: 14px;
                                        }
                                        svg {
                                            width: 65%;
                                            height: auto;
                                            path, circle, rect, line, polygon, ellipse {
                                                fill: $default-green-color !important;
                                            }
                                        }
                                    }
    
                                    .details-wrapper {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        flex-direction: column;
                                        flex: 1;
                                        span {
                                            &.name {
                                                color: $default-text-color-dark;
                                                font-weight: 500;
                                                font-size: 11px;
                                                white-space: pre-line;
                                                text-align: left;
                                            }
                                            &.rate {
                                                display: block;
                                                color: $default-text-color-light;
                                                font-size: 11px;
                                                font-weight: 500;
                                                text-align: left;
                                            }
                                        }
                                    }
    
                                    &.as-active-menu-group {
                                        border-color: transparentize($default-green-color, 0.5);
                                        background-color: transparentize($default-green-color, 0.9);
                                        .icon-wrapper {
                                            background-color: #fff;
                                        }
                                    }
                                }

                                &.as-menu-group {
                                    justify-content: flex-start;
                                    .details-wrapper {
                                        padding-top: 5px;
                                        padding-bottom: 5px;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
    
                                &.as-menu-item {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    height: 140px;
                                    margin: 0;

                                    .icon-wrapper {
                                        --menu-btn-icon-wrapper-size: 45px;
                                        height: var(--menu-btn-icon-wrapper-size);
                                        width: var(--menu-btn-icon-wrapper-size);
                                        border-radius: var(--menu-btn-icon-wrapper-size);
                                        background-color: transparentize($default-green-color, 0.9);
                                        margin-right: unset;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        .icon {
                                            color: $default-green-color;
                                            font-size: calc(var(--menu-btn-icon-wrapper-size) * 0.5);
                                        }
                                        svg {
                                            width: 65%;
                                            height: auto;
                                            path, circle, rect, line, polygon, ellipse {
                                                fill: $default-green-color !important;
                                            }
                                        }
                                    }

                                    .details-wrapper {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        flex: 1;
                                        span {
                                            &.name {
                                                color: $default-text-color-dark;
                                                font-weight: 500;
                                                font-size: 11px;
                                                text-align: center;

                                                display: -webkit-box;
                                                max-width: 100%;
                                                -webkit-line-clamp: 4;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                            }
                                            &.rate {
                                                display: block;
                                                color: $default-text-color-light;
                                                font-size: 10px;
                                                font-weight: 500;
                                                text-align: center;
                                                margin-top: 5px;
                                            }
                                        }
                                    }

                                    &:active {
                                        border-color: transparentize($default-green-color, 0.5);
                                        background-color: transparentize($default-green-color, 0.9);
                                        .icon-wrapper {
                                            background-color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .menu-category-container {
                        grid-column-end: span 1;
                        border-right: 1px solid transparentize($default-border-color, 0.65);
                        .menu-header {
                            padding: 5px 10px 10px 5px;
                        }
                        .menu-body {
                            padding-right: 5px;
                        }
                    }
                    .menu-items-container {
                        grid-column-end: -1;
                        grid-template: 1fr;
                        .menu-header {
                            padding: 5px 10px 10px;
                        }
                        .menu-body {
                            padding-left: 5px;
                        }
                    }
                }
    
                .menu-group {
                    button {
                        box-sizing: border-box;
                        text-align: center;
                        cursor: pointer;
                        text-decoration: none;
                        user-select: none;
                        -webkit-font-smoothing: antialiased;
                        -webkit-user-drag: none;
                        -webkit-user-select: none;
                        font-size: $default-font-size;
                        letter-spacing: 0.25px;
                        font-weight: 500;
                        overflow: hidden;
    
                        &.as-menu-group {
                            padding: 10px 5px;
                            height: 140px;
                            width: 100px;
                            border-radius: 20px;
                            white-space: normal;
                            border: 1px solid transparent;
                            color: $default-text-color;
                            background: #fff;
                            margin: 5px 10px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            position: relative;
                            box-shadow: 1px 2px 5px transparentize($default-border-color, 0.5);
    
                            svg {
                                &.as-local-svg-table {
                                    position: absolute;
                                    top: 10% !important;
                                    width: 60% !important;
                                    height: auto !important;
                                    padding: 8px 4px !important;
                                    border: 1px solid transparentize($default-border-color, 0.5) !important;
                                    border-radius: 15px !important;
    
                                    path, rect {
                                        fill: $default-border-color !important;
                                    }
                                }
                            }
    
                            span {
                                position: absolute;
                                bottom: 5px;
                                padding-bottom: 5px;
                                margin: 0;
                                font-size: 10px;
                                white-space: pre-line;
                                max-width: 70px;
                                max-height: 65px;
                                overflow: hidden;
                                // text-overflow: ellipsis;
                            }
    
                            &.as-active-menu-group {
                                color: $default-text-color;
                                background: lighten($default-menu-category-highlight-color, 30%);
    
                                svg {
                                    &.as-local-svg-table {
                                        background: #fff;
                                        border: 1px solid transparent !important;
        
                                        path, rect {
                                            fill: lighten($default-menu-category-highlight-color, 15%) !important;
                                        }
                                    }
                                }
        
                                span {
                                    color: $default-text-color-dark !important;
                                }
    
                            }
    
                        }
    
                    }
    
                }
            }

        }

    }
    
}

// .as-order-content {
//     height: 82vh;
// }


button {
    &.as-option-btns {
        all: unset !important;
        border: 2px solid transparent !important;
        background: transparent !important;
        color: $default-text-color !important;
        border-radius: 5px !important;
        text-align: left !important;
        padding: 10px !important;
        margin: 2px !important;
        width: 100% !important;
        // display: block !important;
        cursor: pointer !important;
        outline: none !important;

        &:hover, &:focus, &:active {
            color: $default-text-color-dark !important;
            background-color: $default-body-background-color !important;
        }
    }
}

.as-switch-menu-btn {
    @include btnProp;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: transparent;
    border: 2px solid transparent;
    font-size: 14px;
    color: $default-text-color-dark;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }

    .icon-wrapper {
        --switch-menu-icon-wrapper-size: 40px;
        height: var(--switch-menu-icon-wrapper-size);
        width: var(--switch-menu-icon-wrapper-size);
        background-color: transparentize($default-green-color, 0.9);
        border-radius: var(--switch-menu-icon-wrapper-size);
        margin-right: calc(var(--switch-menu-icon-wrapper-size) * 0.5);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            color: $default-green-color;
        }
        svg {
            width: 65%;
            height: auto;
            path, circle, rect, line, polygon, ellipse {
                fill: $default-green-color !important;
            }
        }
    }
    
}


.billing-details {
    padding: 0;
}

.info-input-wrapper {
    position: relative !important;

    span {
        &.overlap {
            position: absolute;
            top: 17px;
            left: 10%;
            // transform: translateY(-50%);
            color: $default-text-color-light;
        }
    }

    input {
        &.info-input-fields {
            width: 100%;
            padding: 5px 10px 5px 55px;
            color: $default-text-color;
            background-color: #fff;
            outline: none;
            border-radius: 5px;
            border: none;
            box-shadow: none !important;
            transition: all 0.2s ease-in-out;
            margin: 0;
    
            &:hover, &:focus {
                border-color: $default-theme-color;
            }
    
            &::placeholder {
                color: $default-border-color;
            }
    
            &:-moz-placeholder {
                color: $default-border-color;
            }
        }
    }

}

span {
    &.selected-name {
        padding: 3px 6px;
        margin: 5px;
        border-radius: 5px;
        background: lighten($color: $default-menu-category-highlight-color, $amount: 30%);
        color: $default-text-color-dark;
        border-left: 5px solid lighten($color: $default-menu-category-highlight-color, $amount: 15%);
        font-size: 10px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: lighten($color: $default-menu-category-highlight-color, $amount: 20%);
            border-color: lighten($color: $default-menu-category-highlight-color, $amount: 8%);
        }

        &.warning {
            background: lighten($color: $default-red-color, $amount: 30%);
            border-color: lighten($color: $default-red-color, $amount: 15%);
            width: 100%;
            &:hover {
                background: lighten($color: $default-red-color, $amount: 20%);
                border-color: lighten($color: $default-red-color, $amount: 8%);
            }
        }

        &.differ {
            background: lighten($color: $default-gray-color, $amount: 30%);
            border-color: lighten($color: $default-gray-color, $amount: 15%);
            width: 100%;
            &:hover {
                background: lighten($color: $default-gray-color, $amount: 20%);
                border-color: lighten($color: $default-gray-color, $amount: 8%);
            }
        }

    }
}

.scrollable {
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    overflow-y: hidden;
    padding-top: 0 !important;
}

// .left-container {
//     .as-order-process-content-touch-order {
//         overflow: hidden;
    
//         .as-order-process-content-body {
//             // height: 72.5vh !important;
//         }
//     }
// }

.right-container {
    .as-order-process-content-touch-order {
        overflow: hidden;
    
        .as-order-process-content-body {
            height: 78vh !important;
        }
    }
}


.other-info-slider {
    --other-info-slider-width: 580px;
    position: fixed;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    width: 100%;

    
    .other-info-slider-container {
        --other-info-slider-container-spacing: 15px;
        --sliding-animation-time: 0.25s;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        bottom: 0;
        padding: calc(var(--other-info-slider-container-spacing) * 0.5);
        width: var(--other-info-slider-width);
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        background-color: #ffffff;

        .header {
            padding: var(--other-info-slider-container-spacing);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .icon-wrapper {
                    --icon-wrapper-size: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: var(--icon-wrapper-size);
                    width: var(--icon-wrapper-size);
                    border-radius: var(--icon-wrapper-size);
                    background-color: transparentize($default-theme-color, 0.9);
                    overflow: hidden;
                    margin-right: calc(var(--icon-wrapper-size) * 0.5);
                    .icon {
                        font-size: calc(var(--icon-wrapper-size) * 0.5);
                        color: $default-theme-color;
                    }
                }
                .title-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        &.title {
                            color: $default-text-color-dark;
                            font-size: 16px;
                            text-align: left;
                        }
                        &.description {
                            color: $default-text-color-light;
                            font-size: 12px;
                            text-align: left;
                        }
                    }
                }
            }
            .action-container {
                button {
                    &.slider-close-btn {
                        @include btnProp;
                        background-color: transparentize(#000, 0.6);
                        color: #fff;
                        font-size: 16px;
                        text-align: center;
                        border-radius: 50%;
                        border: 1px solid transparent;
                        box-shadow: none;
                    }
                }
            }
        }
        .body {
            flex: 1;
            padding: var(--other-info-slider-container-spacing);
            overflow-y: auto;
        }
        .footer {
            padding: var(--other-info-slider-container-spacing);
        }
    }

    &.show-info-slider {
        .other-info-slider-container {
            right: 0;
            box-shadow: 0px 0px 10px 10px transparentize(#000000, 0.96);
            animation: openInfoSlider var(--sliding-animation-time) ease-in-out;
            -webkit-animation: openInfoSlider var(--sliding-animation-time) ease-in-out;
            -moz-animation: openInfoSlider var(--sliding-animation-time) ease-in-out;
            @keyframes openInfoSlider {
                0% {
                    transform: translateX(var(--other-info-slider-width));
                }
                100% {
                    transform: translateX(0px);
                }
            }
            @-webkit-keyframes openInfoSlider {
                0% {
                    transform: translateX(var(--other-info-slider-width));
                }
                100% {
                    transform: translateX(0px);
                }
            }
            @-moz-keyframes openInfoSlider {
                0% {
                    transform: translateX(var(--other-info-slider-width));
                }
                100% {
                    transform: translateX(0px);
                }
            }
        }
    }

    &.hide-info-slider {
        width: unset !important;
        .other-info-slider-container {
            animation: hideOtherInfoSlider var(--sliding-animation-time) ease-in-out;
            -webkit-animation: hideOtherInfoSlider var(--sliding-animation-time) ease-in-out;
            -moz-animation: hideOtherInfoSlider var(--sliding-animation-time) ease-in-out;
            @keyframes hideOtherInfoSlider {
                0% {
                    transform: translateX(0px);
                }
                100% {
                    transform: translateX(var(--other-info-slider-width));
                }
            }
            @-webkit-keyframes hideOtherInfoSlider {
                0% {
                    transform: translateX(0px);
                }
                100% {
                    transform: translateX(var(--other-info-slider-width));
                }
            }
            @-moz-keyframes hideOtherInfoSlider {
                0% {
                    transform: translateX(0px);
                }
                100% {
                    transform: translateX(var(--other-info-slider-width));
                }
            }
        }
    }
    
    .other-info-keyboard-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: var(--other-info-slider-width);
        width: 100%;
        &.numeric {
            width: 80%;
        }
    }

}




.large-touch-input {
    * {
        input {
            &[type = number], &[type = text] {
                padding: 9px;
            }
        }

        .react-datepicker {
            font-size: 14px !important;
        
            .react-datepicker__month-container {
                .react-datepicker__header {
                    .react-datepicker__day-names {
                        .react-datepicker__day-name {
                            color: $default-theme-color !important;
                            width: 45px;
                        }
                    }
        
                }
                .react-datepicker__week {
                    .react-datepicker__day {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            
        }
        .react-datepicker__month-text {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
    }
}